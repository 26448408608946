import { Controller, useForm } from "react-hook-form";
import { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { 
  getConflicts,
  getFundingAssuranceChecks,
  getMatterTypes,
  getMatterStages,
  getRiskRatings,
  updateMatter,
  getUninvoicedMatterRecordableItemsCount,
  createNilMatterInvoice
} from "actions/matter";
import { CreateOrUpdateMatterModel } from "models/create/CreateOrUpdateMatterModel";
import { vestResolver } from "@hookform/resolvers/vest";
import Loader from "components/Loader";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { MatterModel } from "models/view/MatterModel";
import { getChargingSchemes } from "actions/chargingSchemes";
import { MatterTypeIds } from "enums/MatterTypeIds";
import CustomSelect from "components/Select/Select";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import ColoredChip from 'components/Select/ColoredChip';
import { MatterStageModel } from "models/view/MatterStageModel";
import { MatterStageTypes } from "enums/MatterStageTypes";
import { MdWarning } from "react-icons/md";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { getPriorities } from "utils/misc";

type Props = {
  matter?: MatterModel,
  setMatter: Dispatch<React.SetStateAction<MatterModel | undefined>>,
  setEdit: Dispatch<React.SetStateAction<boolean>>
}

function EditMatterSummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [matterStageType, setMatterStageType] = useState<MatterStageTypes>();
  const { id } = useParams();
  const modalActions = useModalActions();

  const {register, handleSubmit, control, reset, watch, formState: {errors}} = useForm<CreateOrUpdateMatterModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    if(props.matter) {
      let initialState: CreateOrUpdateMatterModel = {
        ...props.matter,
      };

      reset(initialState);
      setMatterStageType(props.matter.matterStage?.matterStageType);
    }
  }, [props.matter]);

  const onSubmit = handleSubmit((data) => submitData(data));
 
  async function submitData(data: CreateOrUpdateMatterModel) {
    setLoading(true);
    if(matterStageType == MatterStageTypes.Closed) {
      getUninvoicedMatterRecordableItemsCount(id!).then((response) => {
        if(response.data > 0) {
          let modal: ModalState = {
            title: "Unbilled time",
            body: "Matter has unbilled time. Do you want to create nil invoice(s) with it?",
            actionText: "Create",
            onAction: () => createNilInvoices(),
            show: true,
            onClose: () => {setLoading(false);},
          }
          modalActions.setModal(modal);
        }
        else {
          updateMatterCall(data);
        }
      })   
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setLoading(false);
      });
    }
    else {
      updateMatterCall(data);
    }
  }

  const updateMatterCall = (data: CreateOrUpdateMatterModel) => {
    removeEmptyFields(data);
    updateMatter(id!, data).then((response) => {
      props.setMatter(response.data);
      props.setEdit(false);
    })   
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const createNilInvoices = () => {
    modalActions.toggleModalLoading();
    createNilMatterInvoice(id!).then(() => {
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setLoading(false);
    });
  };

  const onChangeMatterStage = (value: MatterStageModel) => {
    setMatterStageType(value.matterStageType); 
  }

  return (
    <>
      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card>
        {loading && <Loader inlineLoader />}

        <Card.Body>
          <Form onSubmit={onSubmit}>
            <Row className="multiple">
              <Form.Group as={Col} sm={6} xxl={3} controlId="fileNumber">
                <Form.Label className="required">File Number</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  className={`${errors?.fileNumber?.message ? 'invalid' : ''}`}
                  {...register("fileNumber", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.fileNumber?.message && (errors.fileNumber.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="displayName">
                <Form.Label className="required">Name</Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors?.displayName?.message ? 'invalid' : ''}`}
                  {...register("displayName", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.displayName?.message && (errors.displayName.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="conflictStatusId">
                <Form.Label className="required">Conflict</Form.Label>
                <Controller
                  control={control}
                  name="conflictStatusId"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="conflictStatusId"
                      inputRef={ref}
                      className={`lp-select${errors?.conflictStatusId?.message ? ' invalid' : ''}`}
                      endpointCall={getConflicts}
                      formatOptionLabel={ColoredChip}
                      value={value}
                      onChange={val => onChange(val?.id ?? null)}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.conflictStatusId?.message && (errors.conflictStatusId.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="matterTypeId">
                <Form.Label className="required">Type</Form.Label>
                <Controller
                  control={control}
                  name="matterTypeId"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="matterTypeId"
                      inputRef={ref}
                      className={`lp-select${errors?.matterTypeId?.message ? ' invalid' : ''}`}
                      endpointCall={getMatterTypes}
                      value={value}
                      isDisabled
                      onChange={val => onChange(val?.id ?? null)}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.matterTypeId?.message && (errors.matterTypeId.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="matterStageId">
                <Form.Label className="required">Stage</Form.Label>
                <Controller
                  control={control}
                  name="matterStageId"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="matterStageId"
                      inputRef={ref}
                      className={`lp-select${errors?.matterStageId?.message ? ' invalid' : ''}`}
                      endpointCall={() => getMatterStages(props.matter?.matterType?.id ?? "")}
                      value={value}
                      onChange={val => { onChange(val?.id ?? null); onChangeMatterStage(val); }}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.matterStageId?.message && (errors.matterStageId.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="chargingSchemeId">
                <Form.Label className="required">Charging Scheme</Form.Label>
                <Controller
                  control={control}
                  name="chargingSchemeId"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="chargingSchemeId"
                      inputRef={ref}
                      className={`lp-select${errors?.chargingSchemeId?.message ? ' invalid' : ''}`}
                      endpointCall={getChargingSchemes}
                      value={value}
                      onChange={val => onChange(val?.id ?? null)}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.chargingSchemeId?.message && (errors.chargingSchemeId.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="fundingAssuranceStatusId">
                <Form.Label className="required">Funding Assurance</Form.Label>
                <Controller
                  control={control}
                  name="fundingAssuranceStatusId"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="fundingAssuranceStatusId"
                      inputRef={ref}
                      className={`lp-select${errors?.fundingAssuranceStatusId?.message ? ' invalid' : ''}`}
                      endpointCall={getFundingAssuranceChecks}
                      formatOptionLabel={ColoredChip}
                      value={value}
                      onChange={val => onChange(val?.id ?? null)}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.fundingAssuranceStatusId?.message && (errors.fundingAssuranceStatusId.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="riskRatingId">
                <Form.Label className="required">Risk Rating</Form.Label>
                <Controller
                  control={control}
                  name="riskRatingId"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="riskRatingId"
                      inputRef={ref}
                      className={`lp-select${errors?.riskRatingId?.message ? ' invalid' : ''}`}
                      endpointCall={getRiskRatings}
                      value={value}
                      onChange={val => onChange(val?.id ?? null)}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.riskRatingId?.message && (errors.riskRatingId.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} sm={6} xxl={3} controlId="priority">
                <Form.Label className="required">Priority</Form.Label>
                <Controller
                  control={control}
                  name="priority"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="priority"
                      inputRef={ref}
                      className={`lp-select${errors?.priority?.message ? ' invalid' : ''}`}
                      options={getPriorities()}
                      value={value}
                      onChange={val => onChange(val?.id ?? null)}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.priority?.message && (errors.priority.message)}
                </Form.Text>
              </Form.Group>

              {watch("matterTypeId") === MatterTypeIds.CriminalLawActingForAccusedId &&
                <>
                  <Form.Group as={Col} sm={6} xxl={3} controlId="urn">
                    <Form.Label>URN</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.matterCriminalDefenceDetail?.urn?.message ? 'invalid' : ''}`}
                      {...register("matterCriminalDefenceDetail.urn", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.matterCriminalDefenceDetail?.urn?.message && (errors.matterCriminalDefenceDetail.urn.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={3} controlId="custodyRecordNumber">
                    <Form.Label>Custody Record Number</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.matterCriminalDefenceDetail?.custodyRecordNumber?.message ? 'invalid' : ''}`}
                      {...register("matterCriminalDefenceDetail.custodyRecordNumber", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.matterCriminalDefenceDetail?.custodyRecordNumber?.message && (errors.matterCriminalDefenceDetail.custodyRecordNumber.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} xxl={3} controlId="dsccLogNumber">
                    <Form.Label>DSCC Log Number</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.matterCriminalDefenceDetail?.dsccLogNumber?.message ? 'invalid' : ''}`}
                      {...register("matterCriminalDefenceDetail.dsccLogNumber", {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.matterCriminalDefenceDetail?.dsccLogNumber?.message && (errors.matterCriminalDefenceDetail.dsccLogNumber.message)}
                    </Form.Text>
                  </Form.Group>
                </>
              }

              <Form.Group as={Col} xs={12} controlId="description">
                <Form.Label className="required">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className={`${errors?.description?.message ? 'invalid' : ''}`}
                  {...register("description", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.description?.message && (errors.description.message)}
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="d-flex justify-content-between">
                <Button variant="success" type="submit">Update</Button>
                <Button variant="secondary-400" onClick={() => props.setEdit(false)}>Cancel</Button>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default EditMatterSummary;
