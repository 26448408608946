import FieldWithImage from "components/Fields/FieldWithImage";
import BooleanField from "components/Fields/BooleanField";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { CalendarEventModel } from "models/CalendarEventModel";
import moment from "moment";
import { cloneElement, ReactElement, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BiLinkExternal } from "react-icons/bi";
import { MdRefresh } from "react-icons/md";
import { DateFormat } from "utils/constants";
import { getReminderTextByNumberOfMinutes, getShowAsTypeName } from "utils/enums";
import { getFreeBusyStatusFromValue, openUrlInNewtab } from 'utils/misc';
import { getDiaryManagerEvent } from "actions/lte";
import { getMatterDashboardEvent } from "actions/matter";
import FieldWithUrl from "components/Fields/FieldWithUrl";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import EditCalendarEvent from "../EditCalendarEvent/EditCalendarEvent";
import useSlidingPanelActions from "actions/slidingPanel";

type Props = {
  eventData?: CalendarEventModel & { lawPageTradingEntity?: DropDownOptionModel },
  lteId?: string,
  graphId?: string,
  matterId?: string,
  reloadCalendarEvents?: Function,
  changeCalendarEvent?: Function
}

export default function ViewCalendarEvent(props: Props) {
  const [loading, setLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [event, setEvent] = useState<CalendarEventModel & { lawPageTradingEntity?: DropDownOptionModel }>();
  const loggedInUser = useAppSelector((state) => state.user);
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const slidingPanelActions = useSlidingPanelActions();

  useEffect(() => {
    if(props.graphId) {
      setLoading(true);

      // If there is a matterId provided, get the event data from the matter endpoint
      if(props.matterId) {
        getMatterDashboardEvent(props.matterId, props.graphId).then((response) => {
          setEvent(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setLoading(false);
        });
      }

      // Else get the event data from the diary manager endpoint
      else {
        getDiaryManagerEvent(props.lteId!, props.graphId).then((response) => {
          setEvent(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setLoading(false);
        });
      }
    } 
    if(props.eventData && !props.graphId) {
      setEvent(props.eventData);
    }

    setSlidingPanelEditButton();
  }, [props.eventData, props.graphId]);

  const setSlidingPanelEditButton = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageCalendars) &&
      (props.graphId || props.eventData?.graphId) && props.lteId
    ) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Calendar Event",
          children: <EditCalendarEvent
            lteId={props.lteId!}
            graphId={props.graphId ?? props.eventData?.graphId!}
            hideMatterSelect={!!props.matterId}
            reloadEvents={props.reloadCalendarEvents}
            changeEvent={props.changeCalendarEvent}
            onSubmitCallback={() => onChildPanelClose(true)}
          />,
          onCancel: () => {
            props.reloadCalendarEvents && props.reloadCalendarEvents(); // Reload in order to restore modified event
            onChildPanelClose()
          }
        });
      });
    }
  };

  const onChildPanelClose = (forceUpdate?: boolean) => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      // If the event was updated, force remove previous eventData from the sliding panel and add the graphId
      // This will force an update of the event data from the sliding panel
      ...(forceUpdate && {
        children: cloneElement(currentSlidingPanelState.children! as ReactElement, {
          lteId: props.lteId!,
          graphId: props.graphId ?? props.eventData?.graphId!,
          eventData: undefined,
        }),
      })
    });
  };

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      {event?.isEditable && event?.resourceUrl &&
        <Row>
          <Col>
            <Button variant="primary" onClick={() => openUrlInNewtab(event?.resourceUrl)}>
              <BiLinkExternal />
              Open with Outlook
            </Button>
          </Col>
        </Row>
      }

      <Row>
        <Col sm={6}>
          <Field
            label={"Title"}
            value={event?.title}
          />
        </Col>

        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Show As"}
            value={getShowAsTypeName(event?.showAs ?? 0)}
            className={getFreeBusyStatusFromValue(event?.showAs ?? 0)}
          />
        </Col>
      </Row>

      {event?.matterId &&
        <Row>
          <Col>
            <FieldWithUrl
              label={"Matter Ref"}
              url={`/matter/${event?.matterId}`}
              urlDisplayText={event?.matterReference}
              openUrlInNewTab
            />
          </Col>
        </Row>
      }

      {!event?.matterId && event?.matterReference &&
        <Row>
          <Col>
            <Field
              label={"Matter Ref"}
              value={event?.matterReference}
            />
          </Col>
        </Row>
      }

      <Row>
        <Col sm={6}>
          <BooleanField
            label={"Private"}
            value={event?.private ?? false}
          />
        </Col>

        <Col sm={6} className="mt-4 mt-sm-0">
          <BooleanField
            label={"Is All Day"}
            value={event?.isAllDay ?? false}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <FieldWithImage label={"Start Date"}>
            {moment(event?.startDate).format(DateFormat.MomentWithTime)}
            {(!(event?.isSingleInstance ?? true)) && <MdRefresh />}
          </FieldWithImage>
        </Col>

        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"End Date"}
            value={moment(event?.endDate).format(DateFormat.MomentWithTime)}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={event?.optionalAttendees && event?.optionalAttendees.length > 0 ? 6 : 12}>
          <Field
            className="lp-email-chips"
            label={'Required Attendees'}
            value={
              event?.requiredAttendees && event?.requiredAttendees.length > 0
                ? event?.requiredAttendees.map((x, index) => <span className="email" key={`${x}_${index}`}>{x}</span>)
                : '—'
            }
          />
        </Col>

        {event?.optionalAttendees &&
          event?.optionalAttendees.length > 0 && (
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              className="lp-email-chips"
              label={'Optional Attendees'}
              value={
                event?.optionalAttendees && event?.optionalAttendees.length > 0
                  ? event?.optionalAttendees.map((x, index) => <span className="email" key={`${x}_${index}`}>{x}</span>)
                  : '—'
              }
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col sm={event?.rooms && event?.rooms.length > 0 ? 6 : 12}>
          <Field
            label={"Reminder Before Start"}
            value={getReminderTextByNumberOfMinutes(event?.reminderMinutesBeforeStart ?? undefined)}
          />
        </Col>

        {event?.rooms &&
          event?.rooms.length > 0 && (
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={'Rooms'}
              value={
                event?.rooms && event?.rooms.length > 0
                  ? event?.rooms.join(', ')
                  : '—'
              }
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col sm={event?.isOnlineMeeting ? 6 : 12}>
          <Field
            label={"Location"}
            value={event?.location || "—"}
          />
        </Col>
        {event?.isOnlineMeeting && (
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Is Online Meeting"}
              value={
                <Button variant="primary" onClick={() => openUrlInNewtab(event?.onlineMeetingUrl)}>
                  Join Teams Meeting
                </Button>
              }
            />
          </Col>
        )}
      </Row>        

      {event?.lawPageTradingEntity && (
        <Row>
          <Col>
            <Field
              label={"Trading Entity"}
              value={event?.lawPageTradingEntity?.name || "—"}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Field
            label={"Description"}
            value={event?.description || "—"}
            className="lp-event-description"
          />
        </Col>
      </Row>
    </>
  );
}
