import Field from "components/Fields/Field";
import FieldWithImage from "components/Fields/FieldWithImage";
import RiskRatingFieldValue from "components/Fields/RiskRatingFieldValue";
import { MatterModel } from "models/view/MatterModel";
import { MatterTypeIds } from "enums/MatterTypeIds";
import { Card, Row, Col } from "react-bootstrap";
import ConflictField from "components/Fields/ConflictField";
import { getPriority } from "utils/enums";

type Props = {
  matter?: MatterModel
}

function ViewMatterSummary(props: Props) {
  return (
    <Card>
      <Card.Body>
        <Row className="multiple">
          <Col sm={6} xxl={3}>
            <Field
              label={"File Number"}
              value={props.matter?.fileNumber}
              canBeCopied
            />
          </Col>
          <Col sm={6} xxl={3}>
            <Field
              label={"LPID"}
              value={`[LPID#${props.matter?.fileNumber}]`}
              canBeCopied
            />
          </Col>
          <Col sm={6} xxl={3}>
            <Field
              label={"Name"}
              value={props.matter?.displayName}
            />
          </Col>
          <Col sm={6} xxl={3}>
            {props.matter?.conflictStatus?.name ? 
              <ConflictField
                label={"Conflict"}
                value={props.matter?.conflictStatus?.name}
              />
              :
              <Field
                label={"Conflict"}
                value={"—"}
              />
            }
          </Col>
          <Col sm={6} xxl={3}>
            <Field
              label={"Type"}
              value={props.matter?.matterType?.name}
            />
          </Col>
          <Col sm={6} xxl={3}>
            <Field
              label={"Stage"}
              value={props.matter?.matterStage?.name}
            />
          </Col>
          <Col sm={6} xxl={3}>
            <Field
              label={"Charging Scheme"}
              value={props.matter?.chargingScheme?.name}
            />
          </Col>
          <Col sm={6} xxl={3}>
            {props.matter?.conflictStatus?.name ? 
              <ConflictField
                label={"Funding Assurance Check"}
                value={props.matter?.fundingAssuranceStatus?.name}
              />
              :
              <Field
                label={"Funding Assurance Check"}
                value={"—"}
              />
            }
          </Col>
          <Col sm={6} xxl={3}>
            <FieldWithImage
              label={"Risk Rating"}
            >
              <RiskRatingFieldValue value={props.matter?.riskRating?.name ?? ""} />
            </FieldWithImage>
          </Col>
          <Col sm={6} xxl={3}>
            <Field
              label={"Priority"}
              value={props.matter?.priority !== undefined ? getPriority(props.matter?.priority) : "—"}
            />
          </Col>

          {props.matter?.matterType?.id === MatterTypeIds.CriminalLawActingForAccusedId &&
            <>
              <Col sm={6} xxl={3}>
                <Field
                  label={"URN"}
                  value={props.matter?.matterCriminalDefenceDetail?.urn}
                  canBeCopied
                />
              </Col>
              <Col sm={6} xxl={3}>
                <Field
                  label={"Custody Record Number"}
                  value={props.matter?.matterCriminalDefenceDetail?.custodyRecordNumber}
                  canBeCopied
                />
              </Col>
              <Col sm={6} xxl={3}>
                <Field
                  label={"DSCC Log Number"}
                  value={props.matter?.matterCriminalDefenceDetail?.dsccLogNumber}
                />
              </Col>
            </>
          }
          <Col xs={12}>
            <Field
              label={"Description"}
              value={props.matter?.description}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ViewMatterSummary;
