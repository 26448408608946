import { ActionButtonTypes } from "enums/ActionButtonTypes"
import { MouseEventHandler } from "react"

const ListItemButtonGrid = (buttonData: {type: ActionButtonTypes, callback: MouseEventHandler<HTMLElement>, id?: string}) => {
  const buttonId = buttonData.id ?? "button";
  switch(buttonData.type) {
    case ActionButtonTypes.View: {
      return <li key={buttonId + "111"} onClick={buttonData.callback}>View Details</li>;
    }
    case ActionButtonTypes.Edit: {
      return <li key={buttonId + "112"} onClick={buttonData.callback}>Edit</li>;
    }
    case ActionButtonTypes.Deactivate: {
      return <li key={buttonId + "113"} onClick={buttonData.callback}>Deactivate</li>;
    }
    case ActionButtonTypes.Activate: {
      return <li key={buttonId + "114"} onClick={buttonData.callback}>Activate</li>;
    }
    case ActionButtonTypes.Delete: {
      return <li key={buttonId + "115"} onClick={buttonData.callback} className="delete">Delete</li>;
    }
    case ActionButtonTypes.EditTaxonomyTerms: {
      return <li key={buttonId + "116"} onClick={buttonData.callback}>Edit Taxonomy Terms</li>;
    }
    case ActionButtonTypes.OpenFile: {
      return <li key={buttonId + "117"} onClick={buttonData.callback}>Open File</li>;
    }
    case ActionButtonTypes.PreviewFile: {
      return <li key={buttonId + "118"} onClick={buttonData.callback}>Preview File</li>;
    }
    case ActionButtonTypes.Copy: {
      return <li key={buttonId + "119"} onClick={buttonData.callback}>Copy</li>;
    }
    case ActionButtonTypes.PreviewEmail: {
      return <li key={buttonId + "120"} onClick={buttonData.callback}>Preview Email</li>;
    }
    case ActionButtonTypes.SaveAttachments: {
      return <li key={buttonId + "121"} onClick={buttonData.callback}>Save Attachments</li>;
    }
    case ActionButtonTypes.LinkToRecordableEvent: {
      return <li key={buttonId + "122"} onClick={buttonData.callback}>Link To Recordable Event</li>;
    }
    case ActionButtonTypes.UnlinkIncidentalExpenses: {
      return <li key={buttonId + "123"} onClick={buttonData.callback}>Unlink Incidental Expenses</li>;
    }
    case ActionButtonTypes.Payment: {
      return <li key={buttonId + "124"} onClick={buttonData.callback}>Payment</li>;
    }
    case ActionButtonTypes.MarkAsDraft: {
      return <li key={buttonId + "125"} onClick={buttonData.callback}>Mark as Draft</li>;
    }
    case ActionButtonTypes.ShowOnMatterStatisticsCards: {
      return <li key={buttonId + "126"} onClick={buttonData.callback}>Show on Statistics Cards</li>;
    }
    case ActionButtonTypes.HideFromFromMatterStatisticsCards: {
      return <li key={buttonId + "127"} onClick={buttonData.callback}>Hide from Statistics Cards</li>;
    }
    case ActionButtonTypes.ViewParentResource: {
      return <li key={buttonId + "128"} onClick={buttonData.callback}>View Resource</li>;
    }
    case ActionButtonTypes.WriteOff: {
      return <li key={buttonId + "129"} onClick={buttonData.callback}>Write-Off</li>;
    }
    case ActionButtonTypes.Download: {
      return <li key={buttonId + "130"} onClick={buttonData.callback}>Download</li>;
    }
    case ActionButtonTypes.Abate: {
      return <li key={buttonId + "131"} onClick={buttonData.callback}>Abate</li>;
    }
    case ActionButtonTypes.ViewProfile: {
      return <li key={buttonId + "132"} onClick={buttonData.callback}>View Profile</li>;
    }
    case ActionButtonTypes.Flag: {
      return <li key={buttonId + "133"} onClick={buttonData.callback}>Flag</li>;
    }
    case ActionButtonTypes.Unflag: {
      return <li key={buttonId + "134"} onClick={buttonData.callback}>Unflag</li>;
    }
    case ActionButtonTypes.IncreasePriority: {
      return <li key={buttonId + "135"} onClick={buttonData.callback}>Increase Priority</li>;
    }
    case ActionButtonTypes.DecreasePriority: {
      return <li key={buttonId + "136"} onClick={buttonData.callback}>Decrease Priority</li>;
    }
    default:
      return <></>;
  }
}

export default ListItemButtonGrid;