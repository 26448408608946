import { ICellRendererParams } from "ag-grid-community";

export default function MatterRefCellRenderer(props: ICellRendererParams & { idFieldName: string }) {

  return (
    <>
      {props.data?.[props.idFieldName] &&
        <a href={`/matter/${props.data?.[props.idFieldName]}`} rel="noreferrer">
          {props.value}
        </a>
      }

      {!props.data?.[props.idFieldName] &&
        <span>{props.value}</span>
      }
    </>
  );
}

MatterRefCellRenderer.defaultProps = {
  idFieldName: 'matterId'
}