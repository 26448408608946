import { cloneElement, ReactElement, useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { decreasePriorityMatterActivityHistory, deleteMatterFile, flagMatterActivityHistory, getMatterFileById, getMatterFilePreview, increasePriorityMatterActivityHistory, unflagMatterActivityHistory } from "actions/matter";
import { MatterFileModel } from "models/view/MatterFileModel";
import { BiLinkExternal } from 'react-icons/bi';
import { getDecreasedPriority, getIncreasedPriority, openUrlInNewtab } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { useAppSelector } from "hooks/appSelector";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import ViewMatterFile from "../ViewMatterFile/ViewMatterFile";
import { UserRoleNames } from "enums/UserRoleNames";
import usePageActions from "actions/page";
import { Priority } from "enums/Priority";

type Props = {
  matterId: string,
  fileId: string,
  activityHistoryId?: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
  isFlagged?: boolean,
  priority?: Priority,
}

function PreviewMatterFile(props: Props){
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterFile, setMatterFile] = useState<MatterFileModel>();
  const [matterFilePreviewUrl, setMatterFilePreviewUrl] = useState<string>();
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    setIsLoading(true);
    const promises: Promise<any>[] = [];
    promises.push(getMatterFileById(props.matterId, props.fileId));
    promises.push(getMatterFilePreview(props.matterId, props.fileId));

    Promise.all(promises).then(([getMatterResponse, getMatterPreviewResponse]) => {
      setMatterFile(getMatterResponse.data);
      setMatterFilePreviewUrl(getMatterPreviewResponse.data.getUrl);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setMatterFile(undefined);
      setMatterFilePreviewUrl(undefined);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [props.fileId]);

  // Set more buttons only after matterFile is loaded as some properties are needed for the buttons
  useEffect(() => {
    if(matterFile) {
      const canManagePdfFiles = matterFile.isInvoiceRelated || 
        (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
        loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
        loggedInUser.userRole?.name != UserRoleNames.Accountant &&
        loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
        !matterFile.isInvoiceRelated);

      if(canManagePdfFiles) {
        setMoreButtons();
      }
    }
  }, [matterFile, isFlagged, priority]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  }

  const setMoreButtons = () => {
    if (!props.isMatterClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        // View Details
        ...(!matterFile?.isDeletedOnStorage ? [{
          type: ActionButtonTypes.View,
          callback: () => onClickViewDetails(),
        }] : []),
        // Taxonomies
        ...((props.hasTaxonomies && props.activityHistoryId) ? [{
          type: ActionButtonTypes.EditTaxonomyTerms, // This replaces the old Edit Taxonomy Terms button from the footer
          callback: () => onClickTaxonomies(),
        }] : []),
        // Open in new tab
        ...((!matterFile?.isDeletedOnStorage && matterFile?.webUrl) ? [{
          type: ActionButtonTypes.OpenFile,
          callback: () => openUrlInNewtab(matterFile?.webUrl)
        }] : []),
        // Flag
        ...(props.activityHistoryId && isFlagged !== undefined ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...(props.activityHistoryId && priority !== undefined ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        ...(!matterFile?.isDeletedOnStorage ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  };

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const onClickViewDetails = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Matter File Details",
        children: <ViewMatterFile
          matterId={props.matterId}
          fileId={props.fileId!}
          activityHistoryId={props.activityHistoryId}
          isMatterClosed={props.isMatterClosed}
          hasTaxonomies={props.hasTaxonomies}
          syncMatterFiles={props.syncMatterFiles}
          isFlagged={isFlagged}
          priority={priority}
          showMoreButtons
        />,
      }
    );
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter File?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.fileId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterFile(matterId, activityId).then(() => {
      // Reload Summary
      pageActions.triggerReloadPage(false);
      // Sync matter files. Reload the whole grid, because we might have RecordableEvents that changed by deleting the email
      props.syncMatterFiles && props.syncMatterFiles();

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return(
    <div className="lp-iframe-panel">
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"File Name"}
            value={matterFile?.name}
          />
        </Col>
      </Row>

      {matterFilePreviewUrl &&
        <>
          <Row className="full-height">
            <Col>
              <iframe src={matterFilePreviewUrl} title="Matter File Preview" height="100%" width="100%"></iframe> 
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-flex justify-content-between">
                <Button variant="primary" onClick={() => openUrlInNewtab(matterFilePreviewUrl)}>
                  <BiLinkExternal />
                  Open in new tab
                </Button>
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default PreviewMatterFile;
