import { useCallback, useEffect, useRef, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import Grid from "components/Grid";
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card, Button } from 'react-bootstrap';
import useGridActions from 'actions/grid';
import CreateMatterForm from 'containers/Matter/CreateMatter/CreateMatterForm';
import { getColumnDefs } from './columnDefs';
import { getMatterOpenStages, getMatters, getMatterTypes } from 'actions/matter';
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { GridIds } from 'enums/GridIds';
import usePageActions from 'actions/page';
import { AgGridReact } from 'ag-grid-react';
import { GridQuickFilterButtonDataModel } from 'models/view/GridQuickFilterButtonDataModel';
import _ from 'lodash';
import { Priority } from 'enums/Priority';
import { MatterGridFilters } from 'enums/MatterGridFilters';
import { RiskRatingIds } from 'enums/RiskRatingIds';

function MatterList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [genericErrors, setGenericErrors] = useState(null);
  const [openStageIds, setOpenStageIds] = useState<string[]>([]);
  const [closedStageIds, setClosedStageIds] = useState<string[]>([]);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = useCallback(() =>
  {
    Promise.all([
      getMatterOpenStages(),
      getMatterTypes(),
    ]).then(([openStages, allStages]) => {
      const openStagesArr: string[] = openStages.data.map((x: any) => x.id);
      const allStagesArr: string[] = allStages.data.flatMap((x: any) => x.children.flatMap((y: any) => y.id));
      const closedStagesArr: string[] = _.difference(allStagesArr, openStagesArr);
      setOpenStageIds(openStagesArr);
      setClosedStageIds(closedStagesArr);

      const columnDefs = getColumnDefs(pageActions.triggerReloadPage, allStages, openStagesArr);
      gridActions.setGridColumnDefs([...columnDefs]);

      loadMatterList();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }, []);

  const loadMatterList = () => {
    getMatters().then((response) => {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  };

  useEffect(() => {
    gridActions.setGridId(GridIds.Matters);
    pageActions.setReloadGridData(loadMatterList);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Add Matter",
        children: <CreateMatterForm />
      });
  };

  const setFilterMyOpen = () => {
    gridRef.current?.api.setFilterModel({
      "matterStage.name": openStageIds,
      "isCurrentUserMatterFeeEarner": [{
        id: 'true',
        name: 'Yes'
      }]
    });
  }

  const setFilterOpen = () => {
    gridRef.current?.api.setFilterModel({
      "matterStage.name": openStageIds
    });
  }

  const setFilterClosed = () => {
    gridRef.current?.api.setFilterModel({
      "matterStage.name": closedStageIds
    });
  }

  const setFilterOpenHighRisk = () => {
    gridRef.current?.api.setFilterModel({
      "matterStage.name": openStageIds,
      "riskRating.name": [RiskRatingIds.High]
    });
  }

  const setFilterAll = () => {
    gridRef.current?.api.setFilterModel(null);
  }

  const setFilterPriority = (priority: Priority) => {
    gridRef.current?.api.setFilterModel({
      "priority": [priority]
    });
  }

  const gridQuickFilters: GridQuickFilterButtonDataModel[] = [
    {
      text: MatterGridFilters.MyOpen,
      callback: setFilterMyOpen
    },
    {
      text: MatterGridFilters.Open,
      callback: setFilterOpen
    },
    {
      text: MatterGridFilters.Closed,
      callback: setFilterClosed
    },
    {
      text: MatterGridFilters.OpenAndHighRisk,
      callback: setFilterOpenHighRisk
    },
    {
      text: MatterGridFilters.All,
      callback: setFilterAll
    },
    {
      text: MatterGridFilters.PriorityHigh,
      callback: () => setFilterPriority(Priority.High)
    },
    {
      text: MatterGridFilters.PriorityMedium,
      callback: () => setFilterPriority(Priority.Medium)
    },
    {
      text: MatterGridFilters.PriorityLow,
      callback: () => setFilterPriority(Priority.Low)
    }
  ];

  return (
    <div className="lp-page-content">
      <Title
        title="Matters"
        type='page'
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) &&
          <Button variant="success" onClick={toggleSlidingPanel}>
            <MdAddCircle />
            Add Matter
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            ref={gridRef}
            onGridReady={onGridReady}
            gridQuickFilters={gridQuickFilters}
            suppressColumnVirtualisation={true}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </div>
  );
}

export default MatterList;
