import SlidingPanel from "components/SlidingPanel/index";
import { useCallback, useEffect, useState } from 'react';
import CustomCalendar from 'components/Calendar/CustomCalendar';
import { Button, Card } from 'react-bootstrap';
import Title from 'components/Title';
import moment from 'moment';
import { MdAdd, MdRefresh } from 'react-icons/md';
import { isMobile } from 'react-device-detect';
import { CalendarEventModel } from 'models/CalendarEventModel';
import useSlidingPanelActions from 'actions/slidingPanel';
import { getCalendarView } from 'actions/lte';
import { View, Views } from 'react-big-calendar';
import { CalendarFreeBusyStatus } from 'enums/CalendarFreeBusyStatus';
import ViewCalendarEvent from "./ViewCalendarEvent/ViewCalendarEvent";
import CreateCalendarEvent from "./CreateCalendarEvent/CreateCalendarEvent";
import ManualAddAppointments from "containers/Matter/AddAppointments/ManualAddAppointments";
import { BiImport } from "react-icons/bi";
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { UserCalendarSettingsModel } from "models/view/UserCalendarSettingsModel";
import { getUserCalendarSettings } from "actions/user";
import { DayOfWeek } from "enums/DayOfWeek";

function UserCalendar() {
  const [events, setEvents] = useState<CalendarEventModel[]>([]);
  const loggedInUser = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('week').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf('week').toDate()
  );
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [view, setView] = useState<View>(Views.WEEK);
  const [windowOnFocus, setWindowOnFocus] = useState(true);
  const user = useAppSelector((state) => state.user);
  const [userCalendarSettings, setUserCalendarSettings] = useState<UserCalendarSettingsModel>();

  const removeDummyEvent = useCallback(() => {
    setEvents(events.filter((event: any) => !event.isDummy));
  }, [events]);
  
  const checkTokensOnFocus = () => {
    setWindowOnFocus(true);
  };

  const onBlur = () => {
    setWindowOnFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', checkTokensOnFocus);
    return () => {
      window.removeEventListener('focus', checkTokensOnFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const getEventsForInterval = useCallback(() => {
    if(startDate != null && endDate != null) {
      setIsLoading(true);
      const userTimeZone = moment.tz.guess();
      getCalendarView(startDate.toISOString(), endDate.toISOString(), userTimeZone).then((response) => {
        setEvents(response.data);
        //clear previous errors
        setGenericErrors(null);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setIsLoading(true);
    getUserCalendarSettings(user.userId!).then((calendarSettings) => {
      setUserCalendarSettings(calendarSettings.data);

      // Get events after getting user calendar settings to prevent events from jumping around
      getEventsForInterval();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }, [getEventsForInterval]);

  const onRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    setIsLoading(true);
    setEvents([]);
  
    if (Array.isArray(range)) {
      setStartDate(range[0]);
      if (range.length === 1) {
        const newEndDate = moment(range[0]).add(1, 'day');
        setEndDate(newEndDate.toDate());
      } else {
        setEndDate(moment(range[range.length - 1]).add(1, 'day').toDate());
      }
    } else {
      setStartDate(range.start);
      setEndDate(moment(range.end).add(1, 'day').toDate());
    }
  };

  const appendDummyEvent = useCallback((event: CalendarEventModel) => {
    setEvents([...events!, {
      graphId: "",
      title: event.title ?? "",
      startDate: event.startDate,
      endDate: event.endDate,
      showAs: event.showAs ?? CalendarFreeBusyStatus.Unknown,
      resourceUrl: "",
      description: "",
      isAllDay: event.isAllDay ?? false,
      isPastEvent: false,
      isOnlineMeeting: false,
      onlineMeetingUrl: "",
      private: false,
      location: "",
      isSingleInstance: true,
      reminderMinutesBeforeStart: 15,
      requiredAttendees: [],
      optionalAttendees: [],
      rooms: [],
      isDummy: true
    }]);
  }, [events]);

  const changeDummyEvent = useCallback((updatedData: CalendarEventModel) => {
    removeDummyEvent();
    appendDummyEvent(updatedData);
  }, [events]);

  const changeSelectedEvent = useCallback((event: CalendarEventModel) => {
    const eventIndex = events.findIndex((x) => x.graphId === event.graphId);
    if(eventIndex !== -1) {
      const newEvents = [...events];
      newEvents[eventIndex] = event;
      setEvents(newEvents);
    }
  }, [events]);
  
  const handleEventClick = (event: any) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'View Calendar Event',
      children: <ViewCalendarEvent
        eventData={event}
        reloadCalendarEvents={getEventsForInterval}
        changeCalendarEvent={changeSelectedEvent}
      />
    });
  };

  const handleSelectSlot = (event: any) => {
    event.startDate = event.start;
    event.endDate = event.end;

    //create a dummy event
    appendDummyEvent(event);

    slidingPanelActions.setSlidingPanel({
      isShown: true,
      onCancel: removeDummyEvent,
      title: 'Create Calendar Event',
      children: <CreateCalendarEvent 
        startDate={event.startDate}
        endDate={event.endDate}
        reloadEvents={getEventsForInterval}
        removeDummyEvent={removeDummyEvent}
        changeDummyEvent={changeDummyEvent}
        isAllDay={moment(event.endDate).isSame(moment(event.startDate).add(1, 'day'), 'day')}
        />
    });
  }

  const onClickCreateCalendarEvent = () => {
    const userLocalTime = moment();
    const nearestLower30Minutes = userLocalTime.clone().startOf('hour').add(Math.floor(userLocalTime.minutes() / 30) * 30, 'minutes');
    const nearestUpper30Minutes = nearestLower30Minutes.clone().add(30, 'minutes');
    handleSelectSlot({
      start: nearestLower30Minutes.toDate(),
      end: nearestUpper30Minutes.toDate(),
    })
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(windowOnFocus) {
        getEventsForInterval();
      }
    }, 300000);

    return () => clearInterval(interval);
  }, [getEventsForInterval, windowOnFocus]);

  const addAppointmentsToMatter = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Import Appointments To Matter",
        children: <ManualAddAppointments />
      }
    );
  }

  return (
    <div className="lp-page-content">
      <Title type="page" title="Calendar">
        {
          <>
            {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageCalendars) &&
              <Button onClick={addAppointmentsToMatter} variant="secondary-400">
                <BiImport />
                <span>Import Appointments</span>
              </Button>
            }
            <Button onClick={getEventsForInterval} variant="primary">
              <MdRefresh />
              <span>Refresh</span>
            </Button>
            {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageCalendars) && isMobile &&
              <Button onClick={onClickCreateCalendarEvent} variant="success">
                <MdAdd />
                <span>Create Appointment</span>
              </Button>
            }
          </>
        }
      </Title>

      {genericErrors && <div className="lp-errors calendar-errors">{genericErrors}</div>}

      <Card className="with-calendar">
        <Card.Body>
          <CustomCalendar
            handleEventClick={handleEventClick}
            handleSelectSlot={handleSelectSlot}
            onRangeChange={onRangeChange}
            events={events}
            isLoading={loading}
            view={view}
            setView={setView}
            selectable={loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageCalendars)}
            firstDayOfWeek={userCalendarSettings?.firstDayOfWeek ? DayOfWeek[userCalendarSettings.firstDayOfWeek as keyof typeof DayOfWeek] ?? 0 : 0}
          />
        </Card.Body>
      </Card>
      <SlidingPanel />
    </div>
  );
}

export default UserCalendar;
