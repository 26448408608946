import { del, delWithBody, get, post, put, setHeader } from "utils/request";
import { CreateOrUpdateMatterFeeEarnerModel } from "models/create/CreateOrUpdateMatterFeeEarnerModel";
import { CreateOrUpdateMatterModel } from "models/create/CreateOrUpdateMatterModel";
import { CreateOrUpdateMatterRecordableEventModel } from "models/create/CreateOrUpdateMatterRecordableEventModel";
import { CreateParticipatingEntityModel } from "models/create/CreateParticipatingEntityModel";
import { UpdateParticipatingEntityModel } from "models/update/UpdateParticipatingEntityModel";
import { ActivityDropdownForRecordTimeModel } from "models/view/ActivityDropdownForRecordTimeModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { DropDownOptionWithChildrenModel } from "models/view/DropDownOptionWithChildrenModel";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import { MatterFeeEarnerPreferredContactModelModel } from "models/view/MatterFeeEarnerPreferredContactModelModel";
import { MatterModel } from "models/view/MatterModel";
import { ParticipatingEntityModel } from "models/view/ParticipatingEntityModel";
import { ValidateFieldModel } from "models/view/ValidateFieldModel";
import { MatterFileModel } from "models/view/MatterFileModel";
import { UpdateMatterActivityHistoryTaxonomyTermsModel } from "models/update/UpdateMatterActivityHistoryTaxonomyTermsModel";
import { MatterNoteModel } from "models/view/MatterNoteModel";
import { CreateOrUpdateMatterNoteModel } from "models/create/CreateOrUpdateMatterNoteModel";
import { UpdateMatterFileDetailsModel } from "models/update/UpdateMatterFileDetailsModel";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { MatterRecordableEventModel } from "models/view/MatterRecordableEventModel";
import { MatterFileHistoryModel } from "models/view/MatterFileHistoryModel";
import { MatterRecordableItemModel } from "models/view/MatterRecordableItemModel";
import { ActivityHistoryCopyModel } from "models/create/ActivityHistoryCopyModel";
import { MatterFilePreviewModel } from "models/view/MatterFilePreviewModel";
import { ActivityHistoryShareModel } from "models/create/ActivityHistoryShareModel";
import { MatterFilePermissionsModel } from "models/view/MatterFilePermissionsModel";
import { DeleteMatterFilePermissionModel } from "models/delete/DeleteMatterFilePermissionModel";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { CreateOrUpdateMatterDisbursementModel } from "models/create/CreateOrUpdateMatterDisbursementModel";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { CreateOrUpdateMatterIncidentalExpensesModel } from "models/create/CreateOrUpdateMatterIncidentalExpensesModel";
import { UpdateMatterIncidentalExpenseModel } from "models/update/UpdateMatterIncidentalExpenseModel";
import { CreateDraftEmailModel } from "models/create/CreateDraftEmailModel";
import { MatterClientLedgerEntryModel } from "models/view/MatterClientLedgerEntryModel";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";
import { UpdateAccountLedgerTransactionEntryModel } from "models/update/UpdateAccountLedgerTransactionEntryModel";
import { LinkMatterIncidentalExpenseModel } from "models/update/LinkMatterIncidentalExpenseModel";
import { UnlinkMatterIncidentalExpenseModel } from "models/update/UnlinkMatterIncidentalExpenseModel";
import { ActivityHistoryMoveModel } from "models/create/ActivityHistoryMoveModel";
import { CreateOrUpdateMatterInvoiceModel } from "models/create/CreateOrUpdateMatterInvoiceModel";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import { MatterInvoiceItemFilterModel } from "models/view/MatterInvoiceItemFilterModel";
import { MatterInvoicePaymentModel } from "models/view/MatterInvoicePaymentModel";
import { CreateOrUpdateMatterInvoicePaymentModel } from "models/create/CreateOrUpdateMatterInvoicePaymentModel";
import { MatterLedgerBalanceSummaryModel } from "models/view/MatterLedgerBalanceSummaryModel";
import { CreatableDropDownOptionModel } from "models/view/CreatableDropDownOptionModel";
import { CalendarEventModel } from "models/CalendarEventModel";
import { CreateOrUpdateMatterReceiveMoneyModel } from "models/create/CreateOrUpdateMatterReceiveMoneyModel";
import { MatterReceiveMoneyModel } from "models/view/MatterReceiveMoneyModel";
import { MatterFundsTransferModel } from "models/view/MatterFundsTransferModel";
import { CreateOrUpdateMatterFundsTransferModel } from "models/create/CreateOrUpdateMatterFundsTransferModel";
import { MatterDisbursementPaymentModel } from "models/view/MatterDisbursementPaymentModel";
import { UpdateWriteOffModel } from "models/update/UpdateWriteOffModel";
import { MatterSummaryInfoModel } from "models/view/MatterSummaryInfoModel";
import { MatterClientLedgerSummaryInfoModel } from "models/view/MatterClientLedgerSummaryInfoModel";
import { MatterEmailModel } from "models/view/MatterEmailModel";
import { ReopenMatterModel } from "models/update/ReopenMatterModel";
import { MatterStageModel } from "models/view/MatterStageModel";
import { TemplateModel } from "models/view/TemplateModel";
import { CreateMatterDocumentModel } from "models/create/CreateMatterDocumentModel";
import { UpdateMatterEmailDetailsModel } from "models/update/UpdateMatterEmailDetailsModel";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import { BulkAddMatterActivityHistoryTaxonomyTermsModel } from "models/create/BulkAddMatterActivityHistoryTaxonomyTermsModel";
import { BulkDeleteMatterActivityHistoryTaxonomyTermsModel } from "models/create/BulkDeleteMatterActivityHistoryTaxonomyTermsModel";
import { MatterRecordableItemsSummaryInfoModel } from "models/view/MatterRecordableItemsSummaryInfoModel";
import { MatterDisbursementsSummaryInfoModel } from "models/view/MatterDisbursementsSummaryInfoModel";
import { MatterIncidentalExpensesSummaryInfoModel } from "models/view/MatterIncidentalExpensesSummaryInfoModel";
import { MatterInvoiceParticipatingEntityModel } from "models/view/MatterInvoiceParticipatingEntityModel";
import { MatterClientLedgerBalanceSummaryModel } from "models/view/MatterClientLedgerBalanceSummaryModel";
import { AccountLedgerTransactionModel } from "models/view/AccountLedgerTransactionModel";
import { MatterReturnMoneyModel } from "models/view/MatterReturnMoneyModel";
import { CreateOrUpdateMatterReturnMoneyModel } from "models/create/CreateOrUpdateMatterReturnMoneyModel";
import { UpdateAbatementModel } from "models/update/UpdateAbatementModel";
import { Priority } from "enums/Priority";

const getMatters = async (): Promise<any> => {
  const response = await get<MatterModel[]>('/management/matter');
  return response;
}

const getMatter = async (id: string, ignoreNotes: boolean): Promise<any> => {
  const response = await get<MatterModel>(`/management/matter/${id}?ignoreNotes=${ignoreNotes}`);
  return response;
}

const searchMatters = async (searchText?: string, onlyOpen?: boolean, onlyClose?: boolean, onlyWithUserAsFeeEarner?: boolean, riskRatingId?: string, priority?: Priority): Promise<any> => {
  let url = '/management/matter/search';
  let queryParams: string[] = [];

  if(searchText){
    queryParams.push(`searchText=${encodeURIComponent(searchText)}`);
  }
  if(onlyOpen){
    queryParams.push(`onlyOpen=${onlyOpen}`);
  }
  if(onlyClose){
    queryParams.push(`onlyClose=${onlyClose}`);
  }
  if(onlyWithUserAsFeeEarner){
    queryParams.push(`onlyWithUserAsFeeEarner=${onlyWithUserAsFeeEarner}`);
  }
  if(riskRatingId){
    queryParams.push(`riskRatingId=${riskRatingId}`);
  }
  if(priority !== undefined){
    queryParams.push(`priority=${priority}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<MatterModel[]>(url);
  return response;
}

const getMattersSummary = async (ignoreMatterId?: string): Promise<any> => {
  let url = '/management/matter/summary';
  if(ignoreMatterId){
    url += `?ignoreMatterId=${ignoreMatterId}`;
  }
  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getOpenMattersSummary = async (ignoreMatterId?: string): Promise<any> => {
  let url = '/management/matter/open/summary';
  if(ignoreMatterId){
    url += `?ignoreMatterId=${ignoreMatterId}`;
  }
  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getOpenMattersSummaryForLTE = async (lteId: string, ignoreMatterId?: string): Promise<any> => {
  let url = `/management/lte/${lteId}/matter/open/summary`;
  if(ignoreMatterId){
    url += `?ignoreMatterId=${ignoreMatterId}`;
  }
  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getCaseStatuses = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/casestatus/summary');
  return response;
}

const getMatterById = async (matterId : string): Promise<any> => {
  const response = await get<MatterModel>(`/management/matter/${matterId}`);
  return response;
}

const getConflicts = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/conflictstatus/summary');
  return response;
}

const getFundingAssuranceChecks = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/fundingassurancestatus/summary');
  return response;
}

const getRiskRatings = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/riskrating/summary');
  return response;
}

const getMatterTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionWithChildrenModel[]>('/management/matterType/summary?withMatterStages=true');
  return response;
}

const getDocumentTemplatesForMatterType = async (matterTypeId: string, templateTypeId?: string): Promise<any> => {
  let url = `/management/matterType/${matterTypeId}/documentTemplate`;
  if(templateTypeId){
    url += `?templateTypeId=${templateTypeId}`;
  }
  const response = await get<TemplateModel[]>(url);
  return response;
}

const getMatterTypesWithoutMatterStages = async (): Promise<any> => {
  const response = await get<DropDownOptionWithChildrenModel[]>('/management/matterType/summary');
  return response;
}

const getMatterStagesSummary = async (matterTypeId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/matterStage/summary?matterTypeId=${matterTypeId}`);
  return response;
}

const getMatterStages = async (matterTypeId: string): Promise<any> => {
  const response = await get<MatterStageModel[]>(`/management/matterStage?matterTypeId=${matterTypeId}`);
  return response;
}

const getMatterOpenStages = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/matterStage/summary/open');
  return response;
}

const createMatter = async (model: CreateOrUpdateMatterModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterModel, MatterModel>('/management/matter', model);
  return response;
}

const updateMatter = async (id: string, model: CreateOrUpdateMatterModel): Promise<any> => {
  var response = await put<CreateOrUpdateMatterModel, MatterModel>(`/management/matter/${id}`, model);
  return response;
}

const reopenMatter = async (id: string, model: ReopenMatterModel): Promise<any> => {
  var response = await post<ReopenMatterModel, MatterModel>(`/management/matter/${id}/reopen`, model);
  return response;
}

const createMatterFolderShortcut = async (matterId: string): Promise<any> => {
  var response = await post<any, any>(`/management/matter/${matterId}/folderShortcut`, undefined);
  return response;
}

const deleteMatter = async (matterId: string): Promise<any> => {
  var response = await del(`/management/matter/${matterId}`);
  return response;
}

const getMatterSummaryInfo = async (matterId: string): Promise<any> => {
  const response = await get<MatterSummaryInfoModel>(`/management/matter/${matterId}/summaryInfo`);
  return response;
}

const getMatterActivityHistory = async (matterId: string): Promise<any> => {
  const response = await get<MatterActivityHistoryModel[]>(`/management/matter/${matterId}/activityHistory`);
  return response;
}

const getMatterActivityHistoryById = async (matterId: string, activityHistoryId: string): Promise<any> => {
  const response = await get<MatterActivityHistoryModel>(`/management/matter/${matterId}/activityHistory/${activityHistoryId}`);
  return response;
}

const getMatterActivityHistoryByFileId = async (matterId: string, matterFileId: string): Promise<any> => {
  const response = await get<MatterActivityHistoryModel>(`/management/matter/${matterId}/file/${matterFileId}/activityHistory`);
  return response;
}

const getMatterActivityHistoryTaxonomyTerms = async (matterId: string, activityHistoryId: string): Promise<any> => {
  const response = await get<TaxonomyTermModel[]>(`/management/matter/${matterId}/activityHistory/${activityHistoryId}/taxonomyTerm`);
  return response;
}

const updateMatterActivityHistoryTaxonomyTerms = async (matterId: string, activityHistoryId: string, model: UpdateMatterActivityHistoryTaxonomyTermsModel): Promise<any> => {
  const response = await put<UpdateMatterActivityHistoryTaxonomyTermsModel, MatterActivityHistoryModel>(`/management/matter/${matterId}/activityHistory/${activityHistoryId}/taxonomyTerm`, model);
  return response;
}

const bulkAddMatterActivityHistoryTaxonomyTerms = async (matterId: string, model: BulkAddMatterActivityHistoryTaxonomyTermsModel): Promise<any> => {
  const response = await post<BulkAddMatterActivityHistoryTaxonomyTermsModel, any>(`/management/matter/${matterId}/bulkAddActivityHistoryTaxonomyTerms`, model);
  return response;
}

const bulkDeleteMatterActivityHistoryTaxonomyTerms = async (matterId: string, model: BulkDeleteMatterActivityHistoryTaxonomyTermsModel): Promise<any> => {
  const response = await post<BulkDeleteMatterActivityHistoryTaxonomyTermsModel, any>(`/management/matter/${matterId}/bulkDeleteActivityHistoryTaxonomyTerms`, model);
  return response;
}

const getMatterClientLedgerEntries = async (matterId: string, matterParticipatingEntityId: string): Promise<any> => {
  const response = await get<MatterClientLedgerEntryModel[]>(`/management/matter/${matterId}/clientLedger/${matterParticipatingEntityId}`);
  return response;
}

const getMatterClientLedgerSummaryInfo = async (matterId: string, matterParticipatingEntityId: string): Promise<any> => {
  const response = await get<MatterClientLedgerSummaryInfoModel>(`/management/matter/${matterId}/clientLedger/${matterParticipatingEntityId}/summaryInfo`);
  return response;
}

const getMatterClientLedgerBalanceSummaries = async (matterId: string): Promise<any> => {
  const response = await get<MatterClientLedgerBalanceSummaryModel[]>(`/management/matter/${matterId}/clientLedger/balance`);
  return response;
}

const getAccountLedgerTransactionById = async (accountLedgerTransactionId: string): Promise<any> => {
  const response = await get<AccountLedgerTransactionModel>(`/management/accountLedgerTransaction/${accountLedgerTransactionId}`);
  return response;
}

const getAccountLedgerTransactionEntriesForTransaction = async (accountLedgerTransactionId: string): Promise<any> => {
  const response = await get<AccountLedgerTransactionEntryModel[]>(`/management/accountLedgerTransaction/${accountLedgerTransactionId}/entry`);
  return response;
}

const getAccountLedgerTransactionEntryById = async (accountLedgerTransactionEntryId: string): Promise<any> => {
  const response = await get<AccountLedgerTransactionEntryModel>(`/management/accountLedgerTransactionEntry/${accountLedgerTransactionEntryId}`);
  return response;
}

const updateAccountLedgerTransactionEntry = async (accountLedgerTransactionEntryId: string, model: UpdateAccountLedgerTransactionEntryModel): Promise<any> => {
  const response = await put<UpdateAccountLedgerTransactionEntryModel, AccountLedgerTransactionEntryModel>(`/management/accountLedgerTransactionEntry/${accountLedgerTransactionEntryId}`, model);
  return response;
}

const syncMatterFiles = async (matterId: string): Promise<any> => {
  const response = await post<any, MatterModel>(`/management/matter/${matterId}/syncFiles`, {});
  return response;
}

const getMatterFiles = async (matterId: string): Promise<any> => {
  const response = await get<MatterFileModel[]>(`/management/matter/${matterId}/file`);
  return response;
}

const getMatterFilesSummary = async (matterId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/file/summary`);
  return response;
}

const getMatterFileById = async (matterId: string, fileId: string): Promise<any> => {
  const response = await get<MatterFileModel>(`/management/matter/${matterId}/file/${fileId}`);
  return response;
}

const getMatterFileHistory = async (matterId: string, fileId: string): Promise<any> => {
  const response = await get<MatterFileHistoryModel[]>(`/management/matter/${matterId}/file/${fileId}/history`);
  return response;
}

const getMatterFilePermissions = async (matterId: string, fileId: string): Promise<any> => {
  const response = await get<MatterFilePermissionsModel>(`/management/matter/${matterId}/file/${fileId}/permission`);
  return response;
}

const updateMatterFileDetails = async (matterId: string, fileId: string, model: UpdateMatterFileDetailsModel): Promise<any> => {
  const response = await put<UpdateMatterFileDetailsModel, MatterFileModel>(`/management/matter/${matterId}/file/${fileId}/details`, model);
  return response;
}

const getMatterFilePreview = async (matterId: string, fileId: string): Promise<any> => {
  const response = await get<MatterFilePreviewModel>(`/management/matter/${matterId}/file/${fileId}/preview`);
  return response;
}

const createDraftEmail = async (matterId: string, data: CreateDraftEmailModel, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await post<CreateDraftEmailModel, any>(`/management/matter/${matterId}/email/createNewEmail`,
    data
  );
  return response;
}

const deleteMatterFile = async (matterId: string, fileId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/file/${fileId}`);
}

const deleteMatterFilePermission = async (matterId: string, fileId: string, data: DeleteMatterFilePermissionModel): Promise<any> => {
  await delWithBody<DeleteMatterFilePermissionModel>(`/management/matter/${matterId}/file/${fileId}/permission`, data);
}

const getMatterNotes = async (matterId: string): Promise<any> => {
  const response = await get<MatterNoteModel[]>(`/management/matter/${matterId}/note`);
  return response;
}

const getMatterNoteById = async (matterId: string, noteId: string): Promise<any> => {
  const response = await get<MatterNoteModel>(`/management/matter/${matterId}/note/${noteId}`);
  return response;
}

const createMatterNote = async (matterId: string, model: CreateOrUpdateMatterNoteModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterNoteModel, MatterNoteModel>(`/management/matter/${matterId}/note`, model);
  return response;
}

const updateMatterNote = async (matterId: string, noteId: string, model: CreateOrUpdateMatterNoteModel): Promise<any> => {
  const response = await put<CreateOrUpdateMatterNoteModel, MatterNoteModel>(`/management/matter/${matterId}/note/${noteId}`, model);
  return response;
}

const uploadFilesToSharepoint = async (matterId: string, model: FormData): Promise<any> => {
  const response = await post<FormData, MatterFileModel[]>(`/management/matter/${matterId}/uploadFileToSharepoint`, model, {
    headers: {
      'Content-Type': "multipart/form-data",
    },
  })
  return response;
}

const deleteMatterNote = async (matterId: string, noteId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/note/${noteId}`);
}

const getParticipatingEntities = async (matterId: string): Promise<any> => {
  const response = await get<ParticipatingEntityModel[]>(`/management/matterToEntity?matterId=${matterId}`);
  return response;
}

const getParticipatingEntityById = async (id : string): Promise<any> => {
  const response = await get<ParticipatingEntityModel>(`/management/matterToEntity/${id}`);
  return response;
}

const validateParticipatingEntityForMatterParticipatingEntity = async (entityId: string, matterParticipatingEntityId?: string, matterId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/matterToEntity/entity/validate`, {
    "value": entityId,
    "currentId": matterParticipatingEntityId,
    "parentResourceId": matterId
  });
  return response;
}

const validateParticipatingEntityClientRole = async (entityRoleId: string, matterParticipatingEntityId?: string, matterId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/matterToEntity/client/validate`, {
    "value": entityRoleId,
    "currentId": matterParticipatingEntityId,
    "parentResourceId": matterId
  });
  return response;
}

const createParticipatingEntity = async (model: CreateParticipatingEntityModel): Promise<any> => {
  const response = await post<CreateParticipatingEntityModel, ParticipatingEntityModel>('/management/matterToEntity', model);
  return response;
}

const updateParticipatingEntity = async (model: UpdateParticipatingEntityModel): Promise<any> => {
  const response = await put<UpdateParticipatingEntityModel, ParticipatingEntityModel>('/management/matterToEntity', model);
  return response;
}

const deleteParticipatingEntity = async (id: string): Promise<any> => {
  await del(`/management/matterToEntity/${id}`);
}

const getMatterFeeEarnersForMatter = async (matterId: string): Promise<any> => {
  const response = await get<MatterFeeEarnerModel[]>(`/management/matter/${matterId}/feeEarner`);
  return response;
}

const getMatterFeeEarnerPreferredContacts = async (matterFeeEarnerId: string): Promise<any> => {
  const response = await get<MatterFeeEarnerPreferredContactModelModel>(`/management/matterFeeEarner/${matterFeeEarnerId}/preferredContacts`);
  return response;
}

const getMatterFeeEarnerById = async (matterFeeEarnerId: string): Promise<any> => {
  const response = await get<MatterFeeEarnerModel>(`/management/matterFeeEarner/${matterFeeEarnerId}`);
  return response;
}

const validateFeeEarnerForMatterFeeEarner = async (feeEarnerId: string, matterFeeEarnerId?: string, matterId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/matterFeeEarner/feeEarner/validate`, {
    "value": feeEarnerId,
    "currentId": matterFeeEarnerId,
    "parentResourceId": matterId
  });
  return response;
}

const addMatterFeeEarner = async (model: CreateOrUpdateMatterFeeEarnerModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterFeeEarnerModel, MatterFeeEarnerModel>('/management/matterFeeEarner', model);
  return response;
}

const updateMatterFeeEarner = async (matterFeeEarnerId: string, model: CreateOrUpdateMatterFeeEarnerModel): Promise<any> => {
  var response = await put<CreateOrUpdateMatterFeeEarnerModel, MatterFeeEarnerModel>(`/management/matterFeeEarner/${matterFeeEarnerId}`, model);
  return response;
}

const deleteMatterFeeEarner = async (matterFeeEarnerId: string): Promise<any> => {
  await del(`/management/matterFeeEarner/${matterFeeEarnerId}`);
}

const getNoteTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/noteType/summary');
  return response;
}

const getPaymentMethods = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/paymentMethod/summary');
  return response;
}

const getMatterActivityHistoryTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/matter/activityHistoryType/summary');
  return response;
}

const getMatterActivityHistorySubtypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/matter/activityHistorySubtype/summary');
  return response;
}

const recordTime = async (model: CreateOrUpdateMatterRecordableEventModel, matterId: string): Promise<any> => {
  const response = await post<CreateOrUpdateMatterRecordableEventModel, any>(`/management/matter/${matterId}/recordableEvent`, model);
  return response;
}

const updateMatterRecordableEvent = async (model: CreateOrUpdateMatterRecordableEventModel, matterId: string, recordableEventId: string): Promise<any> => {
  const response = await put<CreateOrUpdateMatterRecordableEventModel, any>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}`, model);
  return response;
}

const getMatterRecordableEvent = async (matterId: string, recordableEventId: string): Promise<any> => {
  const response = await get<MatterRecordableEventModel>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}`);
  return response;
}

const getMatterRecordableEventsSummary = async (matterId: string, incidentalExpenseId?: string): Promise<any> => {
  let url = `/management/matter/${matterId}/recordableEvent/summary`;
  if(incidentalExpenseId){
    url += `?incidentalExpenseId=${incidentalExpenseId}`;
  }
  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getMatterRecordableEventWithItems = async (matterId: string, recordableEventId: string): Promise<any> => {
  const response = await get<MatterRecordableEventModel>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}?withItems=true`);
  return response;
}

const getMatterRecordableItems = async (matterId: string, recordableEventId: string): Promise<any> => {
  const response = await get<MatterRecordableItemModel[]>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}/recordableItem`);
  return response;
}

const getMatterRecordableItemsForMatter = async (matterId: string): Promise<any> => {
  const response = await get<MatterRecordableItemModel[]>(`/management/matter/${matterId}/recordableItem`);
  return response;
}

const getMatterRecordableItemsSummaryInfo = async (matterId: string): Promise<any> => {
  const response = await get<MatterRecordableItemsSummaryInfoModel>(`/management/matter/${matterId}/recordableItem/summaryInfo`);
  return response;
}

const getLteRecordableItemsSummaryInfo = async (userIds?: string[], startDate?: Date, endDate?: Date): Promise<any> => {
  let url = `/management/recordableItem/summaryInfo`;
  let queryParams: string[] = [];

  if(userIds && userIds.length > 0){
    userIds.forEach(function (userId) {
      queryParams.push(`userIds=${userId}`);
    });
  }
  if(startDate){
    queryParams.push(`startDate=${startDate.toISOString()}`);
  }
  if(endDate){
    queryParams.push(`endDate=${endDate.toISOString()}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<MatterRecordableItemsSummaryInfoModel>(url);
  return response;
}

const getMatterRecordableItemsForLte = async (userIds?: string[], startDate?: Date, endDate?: Date): Promise<any> => {
  let url = `/management/recordableItem`;
  let queryParams: string[] = [];

  if(userIds && userIds.length > 0){
    userIds.forEach(function (userId) {
      queryParams.push(`userIds=${userId}`);
    });
  }
  if(startDate){
    queryParams.push(`startDate=${startDate.toISOString()}`);
  }
  if(endDate){
    queryParams.push(`endDate=${endDate.toISOString()}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<MatterRecordableItemModel[]>(url);
  return response;
}

const getMatterRecordableItemsForInvoice = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<MatterRecordableItemModel[]>(`/management/matter/${matterId}/invoice/${invoiceId}/recordableItem`);
  return response;
}

const getUninvoicedMatterRecordableItems = async (matterId: string, filter: MatterInvoiceItemFilterModel): Promise<any> => {
  const response = await post<MatterInvoiceItemFilterModel, MatterRecordableItemModel[]>(`/management/matter/${matterId}/recordableItem/uninvoiced`, filter);
  return response;
}

const getUninvoicedMatterRecordableItemsCount = async (matterId: string): Promise<any> => {
  const response = await get<number>(`/management/matter/${matterId}/recordableItem/uninvoiced/count`);
  return response;
}

const getMatterRecordableItem = async (matterId: string, recordableEventId: string, recordableItemId: string): Promise<any> => {
  const response = await get<MatterRecordableItemModel>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}/recordableItem/${recordableItemId}`);
  return response;
}

const deleteMatterRecordableItem = async (matterId: string, recordableEventId: string, recordableItemId: string): Promise<any> => {
  const response = await del(`/management/matter/${matterId}/recordableEvent/${recordableEventId}/recordableItem/${recordableItemId}`);
  return response;
}

const deleteMatterRecordableEvent = async (matterId: string, recordableEventId: string): Promise<any> => {
  const response = await del(`/management/matter/${matterId}/recordableEvent/${recordableEventId}`);
  return response;
}

const getActivitiesForChargingSchemeFeeEarnerAndEventType = async (chargingSchemeId: string, feeEarnerLevelId: string, eventTypeId: string): Promise<any> => {
  const response = await get<ActivityDropdownForRecordTimeModel>(`/management/chargingScheme/${chargingSchemeId}/feeEarnerLevel/${feeEarnerLevelId}/recordableEventType/${eventTypeId}`);
  return response;
}

const getActivitiesForChargingSchemeFeeEarner = async (chargingSchemeId: string, feeEarnerLevelId: string): Promise<any> => {
  const response = await get<ActivityDropdownForRecordTimeModel>(`/management/chargingScheme/${chargingSchemeId}/feeEarnerLevel/${feeEarnerLevelId}/activity`);
  return response;
}

const getFeeEarnerFromMatterFeeEarnerOrDefault = async (matterId: string, userId: string): Promise<any> => {
  const response = await get<DropDownOptionModel | undefined>(`/management/matter/${matterId}/user/${userId}/feeEarnerLevel`);
  return response;
}

const downloadActivityHistory = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/downloadActivityHistory`, activityHistoryIds, {responseType: 'blob'});
  return response;
}

const createPdfBundle = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/pdfBundle`, activityHistoryIds, {responseType: 'blob'});
  return response;
}

const printActivityHistory = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/printActivityHistory`, activityHistoryIds, {responseType: 'blob'});
  return response;
}

const exportActivityHistory = async (matterId: string, keepAlive: boolean, idToken?: string): Promise<any> => {
  if(keepAlive) {
    const response = fetch(`${process.env.REACT_APP_API_URL}/management/matter/${matterId}/exportActivityHistory`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${idToken}`, 
      },
      keepalive: true,
    });

    return response;
  }
  const response = await get<any>(`/management/matter/${matterId}/exportActivityHistory`);
  return response;
}

const validateCopyActivityHistory = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/copyActivityHistory/validate`, activityHistoryIds);
  return response;
}

const copyActivityHistory = async (matterId: string, data: ActivityHistoryCopyModel): Promise<any> => {
  const response = await post<ActivityHistoryCopyModel, any>(`/management/matter/${matterId}/copyActivityHistory`, data);
  return response;
}

const validateMoveActivityHistory = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/moveActivityHistory/validate`, activityHistoryIds);
  return response;
}

const moveActivityHistory = async (matterId: string, data: ActivityHistoryMoveModel): Promise<any> => {
  const response = await post<ActivityHistoryMoveModel, any>(`/management/matter/${matterId}/moveActivityHistory`, data);
  return response;
}

const validateConvertActivityHistoryEmailFilesToEmails = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/convertActivityHistoryToEmail/validate`, activityHistoryIds);
  return response;
}

const convertActivityHistoryEmailFilesToEmails = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/convertActivityHistoryToEmail`, activityHistoryIds);
  return response;
}

const validateShareActivityHistory = async (matterId: string, activityHistoryIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/shareActivityHistory/validate`, activityHistoryIds);
  return response;
}

const shareActivityHistory = async (matterId: string, data: ActivityHistoryShareModel): Promise<any> => {
  const response = await post<ActivityHistoryShareModel, any>(`/management/matter/${matterId}/shareActivityHistory`, data);
  return response;
}

const getUninvoicedMatterDisbursements = async (matterId: string, filter: MatterInvoiceItemFilterModel): Promise<any> => {
  const response = await post<MatterInvoiceItemFilterModel, MatterDisbursementModel[]>(`/management/matter/${matterId}/disbursement/uninvoiced`, filter);
  return response;
}

const getMatterDisbursements = async (matterId: string): Promise<any> => {
  const response = await get<MatterDisbursementModel[]>(`/management/matter/${matterId}/disbursement`);
  return response;
}

const getMatterDisbursementsForInvoice = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<MatterDisbursementModel[]>(`/management/matter/${matterId}/invoice/${invoiceId}/disbursement`);
  return response;
}

const getMatterDisbursementAccounts = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/matterDisbursement/account/summary');
  return response;
}

const getMatterDisbursementsSummaryInfo = async (matterId: string): Promise<any> => {
  const response = await get<MatterDisbursementsSummaryInfoModel>(`/management/matter/${matterId}/disbursement/summaryInfo`);
  return response;
}

const getMatterDisbursementById = async (matterId: string, disbursementId: string): Promise<any> => {
  const response = await get<MatterDisbursementModel>(`/management/matter/${matterId}/disbursement/${disbursementId}`);
  return response;
}

const getMatterDisbursementPayments = async (matterId: string, disbursementId: string): Promise<any> => {
  const response = await get<MatterDisbursementPaymentModel[]>(`/management/matter/${matterId}/disbursement/${disbursementId}/payment`);
  return response;
}

const createMatterDisbursement = async (matterId: string, model: CreateOrUpdateMatterDisbursementModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterDisbursementModel, MatterDisbursementModel>(`/management/matter/${matterId}/disbursement`, model);
  return response;
}

const updateMatterDisbursement = async (matterId: string, disbursementId: string, model: CreateOrUpdateMatterDisbursementModel): Promise<any> => {
  const response = await put<CreateOrUpdateMatterDisbursementModel, MatterDisbursementModel>(`/management/matter/${matterId}/disbursement/${disbursementId}`, model);
  return response;
}

const updateMatterDisbursementWriteOff = async (matterId: string, disbursementId: string, model: UpdateWriteOffModel): Promise<any> => {
  const response = await put<UpdateWriteOffModel, MatterDisbursementModel>(`/management/matter/${matterId}/disbursement/${disbursementId}/writeOff`, model);
  return response;
}

const printMatterDisbursements = async (matterId: string, disbursementIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/disbursement/print`, disbursementIds, {responseType: 'blob'});
  return response;
}

const downloadMatterDisbursements = async (matterId: string, disbursementIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/disbursement/download`, disbursementIds, {responseType: 'blob'});
  return response;
}

const deleteMatterDisbursementWriteOff = async (matterId: string, disbursementId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/disbursement/${disbursementId}/writeOff`);
}

const deleteMatterDisbursement = async (matterId: string, disbursementId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/disbursement/${disbursementId}`);
}

const getMatterEmailById = async (matterId: string, emailId: string): Promise<any> => {
  const response = await get<MatterEmailModel>(`/management/matter/${matterId}/email/${emailId}`);
  return response;
}

const updateMatterEmailDetails = async (matterId: string, emailId: string, model: UpdateMatterEmailDetailsModel): Promise<any> => {
  const response = await put<UpdateMatterEmailDetailsModel, MatterEmailModel>(`/management/matter/${matterId}/email/${emailId}/details`, model);
  return response;
}

const getMatterEmailPreview = async (matterId: string, emailId: string): Promise<any> => {
  const response = await get<MatterFilePreviewModel>(`/management/matter/${matterId}/email/${emailId}/preview`);
  return response;
}

const deleteMatterEmail = async (matterId: string, emailId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/email/${emailId}`);
}

const getMatterIncidentalExpenses = async (matterId: string): Promise<any> => {
  const response = await get<MatterIncidentalExpenseModel[]>(`/management/matter/${matterId}/incidentalExpense`);
  return response;
}

const getMatterIncidentalExpensesForLte = async (userIds?: string[], startDate?: Date, endDate?: Date): Promise<any> => {
  let url = `/management/incidentalExpense`;
  let queryParams: string[] = [];

  if(userIds && userIds.length > 0){
    userIds.forEach(function (userId) {
      queryParams.push(`userIds=${userId}`);
    });
  }
  if(startDate){
    queryParams.push(`startDate=${startDate.toISOString()}`);
  }
  if(endDate){
    queryParams.push(`endDate=${endDate.toISOString()}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<MatterIncidentalExpenseModel[]>(url);
  return response;
}

const getUninvoicedMatterIncidentalExpenses = async (matterId: string, filter: MatterInvoiceItemFilterModel): Promise<any> => {
  const response = await post<MatterInvoiceItemFilterModel, MatterIncidentalExpenseModel[]>(`/management/matter/${matterId}/incidentalExpense/uninvoiced`, filter);
  return response;
}

const getMatterIncidentalExpensesForRecordableEvent = async (matterId: string, recordableEventId: string): Promise<any> => {
  const response = await get<MatterIncidentalExpenseModel[]>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}/incidentalExpense`);
  return response;
}

const getMatterIncidentalExpensesForInvoice = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<MatterIncidentalExpenseModel[]>(`/management/matter/${matterId}/invoice/${invoiceId}/incidentalExpense`);
  return response;
}

const getMatterIncidentalExpensesForRecordableEventSummary = async (matterId: string, recordableEventId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}/incidentalExpense/summary`);
  return response;
}

const getMatterIncidentalExpensesSummaryInfo = async (matterId: string): Promise<any> => {
  const response = await get<MatterIncidentalExpensesSummaryInfoModel>(`/management/matter/${matterId}/incidentalExpense/summaryInfo`);
  return response;
}

const getLteIncidentalExpensesSummaryInfo = async (userIds?: string[], startDate?: Date, endDate?: Date): Promise<any> => {
  let url = `/management/incidentalExpense/summaryInfo`;
  let queryParams: string[] = [];

  if(userIds && userIds.length > 0){
    userIds.forEach(function (userId) {
      queryParams.push(`userIds=${userId}`);
    });
  }
  if(startDate){
    queryParams.push(`startDate=${startDate.toISOString()}`);
  }
  if(endDate){
    queryParams.push(`endDate=${endDate.toISOString()}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<MatterIncidentalExpensesSummaryInfoModel>(url);
  return response;
}

const getMatterIncidentalExpenseById = async (matterId: string, incidentalExpenseId: string): Promise<any> => {
  const response = await get<MatterIncidentalExpenseModel>(`/management/matter/${matterId}/incidentalExpense/${incidentalExpenseId}`);
  return response;
}

const getEmailSuggestions = async (matterId: string): Promise<any> => {
  const response = await get<CreatableDropDownOptionModel[]>(`/management/matter/${matterId}/email/suggestion`);
  return response;
}

const getEventAttendeeSuggestions = async (lteId: string, matterId: string): Promise<any> => {
  const response = await get<CreatableDropDownOptionModel[]>(`/management/lte/${lteId}/matter/${matterId}/eventAttendee/suggestion`);
  return response;
}

const getEmailAttachmentsSuggestions = async (matterId: string, emailId: string): Promise<any> => {
  const response = await get<string>(`/management/matter/${matterId}/userEmail/${emailId}/attachments`);
  return response;
}

const addEmailAttachments = async (matterId: string, emailId: string, attachmentsIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/userEmail/${emailId}/attachments/save`, attachmentsIds);
  return response;
}

const createMatterIncidentalExpenses = async (matterId: string, model: CreateOrUpdateMatterIncidentalExpensesModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterIncidentalExpensesModel, MatterIncidentalExpenseModel>(`/management/matter/${matterId}/incidentalExpense`, model);
  return response;
}

const updateMatterIncidentalExpense = async (matterId: string, incidentalExpenseId: string, model: UpdateMatterIncidentalExpenseModel): Promise<any> => {
  const response = await put<UpdateMatterIncidentalExpenseModel, MatterIncidentalExpenseModel>(`/management/matter/${matterId}/incidentalExpense/${incidentalExpenseId}`, model);
  return response;
}

const updateMatterIncidentalExpenseWriteOff = async (matterId: string, incidentalExpenseId: string, model: UpdateWriteOffModel): Promise<any> => {
  const response = await put<UpdateWriteOffModel, MatterIncidentalExpenseModel>(`/management/matter/${matterId}/incidentalExpense/${incidentalExpenseId}/writeOff`, model);
  return response;
}

const deleteMatterIncidentalExpenseWriteOff = async (matterId: string, incidentalExpenseId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/incidentalExpense/${incidentalExpenseId}/writeOff`);
}

const linkMatterIncidentalExpense = async (matterId: string, incidentalExpenseId: string, model: LinkMatterIncidentalExpenseModel): Promise<any> => {
  const response = await post<LinkMatterIncidentalExpenseModel, MatterIncidentalExpenseModel>(`/management/matter/${matterId}/incidentalExpense/${incidentalExpenseId}/link`, model);
  return response;
}

const unlinkMatterIncidentalExpenses = async (matterId: string, recordableEventId: string, model: UnlinkMatterIncidentalExpenseModel): Promise<any> => {
  const response = await post<UnlinkMatterIncidentalExpenseModel, any>(`/management/matter/${matterId}/recordableEvent/${recordableEventId}/incidentalExpense/unlink`, model);
  return response;
}

const printMatterIncidentalExpenses = async (matterId: string, incidentalExpenseIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/incidentalExpense/print`, incidentalExpenseIds, {responseType: 'blob'});
  return response;
}

const downloadMatterIncidentalExpenses = async (matterId: string, incidentalExpenseIds: string[]): Promise<any> => {
  const response = await post<string[], any>(`/management/matter/${matterId}/incidentalExpense/download`, incidentalExpenseIds, {responseType: 'blob'});
  return response;
}

const deleteMatterIncidentalExpense = async (matterId: string, incidentalExpenseId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/incidentalExpense/${incidentalExpenseId}`);
}

const getMatterParticipatingEntitiesSummary = async (matterId: string, isinvoicingParty?: boolean, entityRoleId?: string): Promise<any> => {
  let url = `/management/matter/${matterId}/participatingEntity/summary`;
  let queryParams: string[] = [];

  if(isinvoicingParty) {
    queryParams.push(`isinvoicingParty=${isinvoicingParty}`);
  }

  if(entityRoleId) {
    queryParams.push(`entityRoleId=${entityRoleId}`);
  }

  if(queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getMatterClientsSummary = async (matterId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/client/summary`);
  return response;
}

const getMatterDefaultClient = async (matterId: string): Promise<any> => {
  const response = await get<ParticipatingEntityModel>(`/management/matter/${matterId}/client/default`);
  return response;
}

const getMatterInvoicingPartiesSummary = async (matterId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/invoicingParty/summary`);
  return response;
}

const getDefaultMatterInvoicingParty = async (matterId: string): Promise<any> => {
  const response = await get<DropDownOptionModel>(`/management/matter/${matterId}/invoicingParty/default`);
  return response;
}

const createReplyDraftEmail = async (matterId: string, emailId: string, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/email/${emailId}/reply`);
  return response;
}

const createReplyAllDraftEmail = async (matterId: string, emailId: string, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/email/${emailId}/replyAll`);
  return response;
}

const createForwardDraftEmail = async (matterId: string, emailId: string, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/email/${emailId}/forward`);
  return response;
}

const getMatterInvoiceStatuses = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/matterInvoiceStatus/summary');
  return response;
}

const getDraftMatterInvoice = async (matterId: string): Promise<any> => {
  const response = await get<MatterInvoiceModel>(`/management/matter/${matterId}/invoice/draft`);
  return response;
}

const getMatterInvoiceById = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<MatterInvoiceModel>(`/management/matter/${matterId}/invoice/${invoiceId}`);
  return response;
}

const createMatterInvoice = async (matterId: string, model: CreateOrUpdateMatterInvoiceModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterInvoiceModel, any>(`/management/matter/${matterId}/invoice`, model);
  return response;
}

const createNilMatterInvoice = async (matterId: string): Promise<any> => {
  const response = await post<any, any>(`/management/matter/${matterId}/invoice/nil`, {});
  return response;
}

const updateMatterInvoice = async (matterId: string, invoiceId: string, model: CreateOrUpdateMatterInvoiceModel): Promise<any> => {
  const response = await put<CreateOrUpdateMatterInvoiceModel, any>(`/management/matter/${matterId}/invoice/${invoiceId}`, model);
  return response;
}

const updateMatterInvoiceWriteOff = async (matterId: string, invoiceId: string, model: UpdateWriteOffModel): Promise<any> => {
  const response = await put<UpdateWriteOffModel, any>(`/management/matter/${matterId}/invoice/${invoiceId}/writeOff`, model);
  return response;
}

const deleteMatterInvoiceWriteOff = async (matterId: string, invoiceId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/invoice/${invoiceId}/writeOff`);
}

const updateMatterInvoiceAbatement = async (matterId: string, invoiceId: string, model: UpdateAbatementModel): Promise<any> => {
  const response = await put<UpdateAbatementModel, any>(`/management/matter/${matterId}/invoice/${invoiceId}/abatement`, model);
  return response;
}

const deleteMatterInvoiceAbatement = async (matterId: string, invoiceId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/invoice/${invoiceId}/abatement`);
}

const markMatterInvoiceAsDraft = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/invoice/${invoiceId}/markAsDraft`, undefined);
  return response;
}

const changeMatterInvoiceShowOnMatterSummary = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/invoice/${invoiceId}/changeShowOnMatterSummary`, undefined);
  return response;
}

const createMatterInvoiceDocument = async (matterId: string, invoiceId: string, detailedDocument: boolean = false): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/invoice/${invoiceId}/createDocument?detailedDocument=${detailedDocument}`, undefined);
  return response;
}

const createMatterInvoiceReceipt = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/invoice/${invoiceId}/createReceipt`, undefined);
  return response;
}

const deleteMatterInvoice = async (matterId: string, invoiceId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/invoice/${invoiceId}`);
}

const getMatterParticipatingEntitiesForInvoice = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<MatterInvoiceParticipatingEntityModel[]>(`/management/matter/${matterId}/invoice/${invoiceId}/client`);
  return response;
}

const getMatterParticipatingEntitiesForInvoiceSummary = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/matter/${matterId}/invoice/${invoiceId}/client/summary`);
  return response;
}

const getMatterInvoicePayments = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<MatterInvoicePaymentModel[]>(`/management/matter/${matterId}/invoice/${invoiceId}/payment`);
  return response;
}

const getMatterInvoicePaymentById = async (matterId: string, invoiceId: string, paymentId: string): Promise<any> => {
  const response = await get<MatterInvoicePaymentModel>(`/management/matter/${matterId}/invoice/${invoiceId}/payment/${paymentId}`);
  return response;
}

const createMatterInvoicePayment = async (matterId: string, invoiceId: string, model: CreateOrUpdateMatterInvoicePaymentModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterInvoicePaymentModel, any>(`/management/matter/${matterId}/invoice/${invoiceId}/payment`, model);
  return response;
}

const deleteMatterInvoicePayment = async (matterId: string, invoiceId: string, paymentId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/invoice/${invoiceId}/payment/${paymentId}`);
}

const getMatterClientAccountBalances = async (matterId: string, invoiceId: string): Promise<any> => {
  const response = await get<MatterLedgerBalanceSummaryModel[]>(`/management/matter/${matterId}/invoice/${invoiceId}/clientAccountBalance`);
  return response;
}

const getMatterDashboardEvents = async (matterId: string, startDate: string, endDate: string, userTimeZone?: string): Promise<any> => {
  let url = `/management/matter/${matterId}/appointments?startDate=${startDate}&endDate=${endDate}`;
  if(userTimeZone){
    url += `&userTimeZone=${encodeURIComponent(userTimeZone)}`;
  }

  const response = await get<CalendarEventModel[]>(url);
  return response;
}

const getMatterDashboardEvent = async (matterId: string, appointmentId: string): Promise<any> => {
  const response = await get<CalendarEventModel[]>(`/management/matter/${matterId}/appointments/${appointmentId}`);
  return response;
}

const getCaseManager = async (matterId: string): Promise<any> => {
  const response = await get<MatterFeeEarnerModel[]>(`/management/matter/${matterId}/caseManager`);
  return response;
}

const getDiaryManager = async (lteId: string): Promise<any> => {
  const response = await get<string>(`/management/lte/${lteId}/diaryManager`);
  return response;
}

const getMatterReceiveMoneyAccounts = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/matterReceiveMoney/account/summary');
  return response;
}

const getReceiveMoneyByTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/receiveMoneyByType/summary');
  return response;
}

const getReceiveMoneyReasons = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/receiveMoneyReason/summary');
  return response;
}

const getMatterReceiveMoneyById = async (matterId: string, receiveMoneyId: string): Promise<any> => {
  const response = await get<MatterReceiveMoneyModel>(`/management/matter/${matterId}/receiveMoney/${receiveMoneyId}`);
  return response;
}

const createMatterReceiveMoney = async (matterId: string, model: CreateOrUpdateMatterReceiveMoneyModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterReceiveMoneyModel, any>(`/management/matter/${matterId}/receiveMoney`, model);
  return response;
}

const updateMatterReceiveMoney = async (matterId: string, receiveMoneyId: string, model: CreateOrUpdateMatterReceiveMoneyModel): Promise<any> => {
  const response = await put<CreateOrUpdateMatterReceiveMoneyModel, any>(`/management/matter/${matterId}/receiveMoney/${receiveMoneyId}`, model);
  return response;
}

const deleteMatterReceiveMoney = async (matterId: string, receiveMoneyId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/receiveMoney/${receiveMoneyId}`);
}

const getMatterReturnMoneyAccounts = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/matterReturnMoney/account/summary');
  return response;
}

const getReturnMoneyByTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/returnMoneyByType/summary');
  return response;
}

const getReturnMoneyReasons = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/returnMoneyReason/summary');
  return response;
}

const getMatterReturnMoneyById = async (matterId: string, returnMoneyId: string): Promise<any> => {
  const response = await get<MatterReturnMoneyModel>(`/management/matter/${matterId}/returnMoney/${returnMoneyId}`);
  return response;
}

const createMatterReturnMoney = async (matterId: string, model: CreateOrUpdateMatterReturnMoneyModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterReturnMoneyModel, any>(`/management/matter/${matterId}/returnMoney`, model);
  return response;
}

const updateMatterReturnMoney = async (matterId: string, returnMoneyId: string, model: CreateOrUpdateMatterReturnMoneyModel): Promise<any> => {
  const response = await put<CreateOrUpdateMatterReturnMoneyModel, any>(`/management/matter/${matterId}/returnMoney/${returnMoneyId}`, model);
  return response;
}

const deleteMatterReturnMoney = async (matterId: string, returnMoneyId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/returnMoney/${returnMoneyId}`);
}

const getFundsTransferTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/fundsTransferType/summary');
  return response;
}

const getMatterFundsTransferById = async (matterId: string, fundsTransferId: string): Promise<any> => {
  const response = await get<MatterFundsTransferModel>(`/management/matter/${matterId}/fundsTransfer/${fundsTransferId}`);
  return response;
}

const createMatterFundsTransfer = async (matterId: string, model: CreateOrUpdateMatterFundsTransferModel): Promise<any> => {
  const response = await post<CreateOrUpdateMatterFundsTransferModel, any>(`/management/matter/${matterId}/fundsTransfer`, model);
  return response;
}

const updateMatterFundsTransfer = async (matterId: string, fundsTransferId: string, model: CreateOrUpdateMatterFundsTransferModel): Promise<any> => {
  const response = await put<CreateOrUpdateMatterFundsTransferModel, any>(`/management/matter/${matterId}/fundsTransfer/${fundsTransferId}`, model);
  return response;
}

const deleteMatterFundsTransfer = async (matterId: string, fundsTransferId: string): Promise<any> => {
  await del(`/management/matter/${matterId}/fundsTransfer/${fundsTransferId}`);
}

const createMatterDocument = async (matterId: string, model: CreateMatterDocumentModel): Promise<any> => {
  const response = await post<CreateMatterDocumentModel, any>(`/management/matter/${matterId}/document`, model);
  return response;
}

const flagMatterActivityHistory = async (matterId: string, activityHistoryId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/activityHistory/${activityHistoryId}/flag`, undefined);
  return response;
}

const unflagMatterActivityHistory = async (matterId: string, activityHistoryId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/activityHistory/${activityHistoryId}/unflag`, undefined);
  return response;
}

const increasePriorityMatterActivityHistory = async (matterId: string, activityHistoryId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/activityHistory/${activityHistoryId}/increasePriority`, undefined);
  return response;
}

const decreasePriorityMatterActivityHistory = async (matterId: string, activityHistoryId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/activityHistory/${activityHistoryId}/decreasePriority`, undefined);
  return response;
}

const increasePriorityMatter = async (matterId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/increasePriority`, undefined);
  return response;
}

const decreasePriorityMatter = async (matterId: string): Promise<any> => {
  const response = await post(`/management/matter/${matterId}/decreasePriority`, undefined);
  return response;
}

export {
  getConflicts,
  getFundingAssuranceChecks,
  getMatterTypes,
  getDocumentTemplatesForMatterType,
  getMatterTypesWithoutMatterStages,
  getMatterStagesSummary,
  getMatterStages,
  getMatterOpenStages,
  getRiskRatings,
  getCaseStatuses,

  getMatters,
  getMatter,
  searchMatters,
  getMattersSummary,
  getOpenMattersSummary,
  getOpenMattersSummaryForLTE,
  getMatterById,
  createMatter,
  updateMatter,
  reopenMatter,
  createMatterFolderShortcut,
  deleteMatter,
  syncMatterFiles,
  getMatterSummaryInfo,
  getMatterActivityHistory,
  getMatterActivityHistoryById,
  getMatterActivityHistoryByFileId,
  getMatterActivityHistoryTaxonomyTerms,
  updateMatterActivityHistoryTaxonomyTerms,
  bulkAddMatterActivityHistoryTaxonomyTerms,
  bulkDeleteMatterActivityHistoryTaxonomyTerms,

  getMatterFiles,
  getMatterFilesSummary,
  getMatterFileById,
  getMatterFileHistory,
  getMatterFilePermissions,
  updateMatterFileDetails,
  getMatterFilePreview,
  deleteMatterFile,
  deleteMatterFilePermission,

  getMatterEmailById,
  updateMatterEmailDetails,
  getMatterEmailPreview,
  getEmailSuggestions,
  createDraftEmail,
  createReplyDraftEmail,
  createReplyAllDraftEmail,
  createForwardDraftEmail,
  deleteMatterEmail,

  getMatterNotes,
  getMatterNoteById,
  createMatterNote,
  updateMatterNote,
  uploadFilesToSharepoint,
  deleteMatterNote,

  getMatterFeeEarnersForMatter,
  getMatterFeeEarnerPreferredContacts,
  getMatterFeeEarnerById,
  validateFeeEarnerForMatterFeeEarner,
  addMatterFeeEarner,
  updateMatterFeeEarner,
  deleteMatterFeeEarner,

  getParticipatingEntities,
  getParticipatingEntityById,
  validateParticipatingEntityForMatterParticipatingEntity,
  validateParticipatingEntityClientRole,
  createParticipatingEntity,
  deleteParticipatingEntity,
  updateParticipatingEntity,

  getNoteTypes,
  getPaymentMethods,
  getMatterActivityHistoryTypes,
  getMatterActivityHistorySubtypes,

  recordTime,
  getMatterRecordableEventsSummary,
  getMatterRecordableEvent,
  updateMatterRecordableEvent,
  getMatterRecordableEventWithItems,
  getMatterRecordableItems,
  getMatterRecordableItemsForMatter,
  getMatterRecordableItemsSummaryInfo,
  getLteRecordableItemsSummaryInfo,
  getMatterRecordableItemsForLte,
  getMatterRecordableItemsForInvoice,
  getUninvoicedMatterRecordableItems,
  getUninvoicedMatterRecordableItemsCount,
  getMatterRecordableItem,
  deleteMatterRecordableItem,
  getActivitiesForChargingSchemeFeeEarnerAndEventType,
  getActivitiesForChargingSchemeFeeEarner,
  getFeeEarnerFromMatterFeeEarnerOrDefault,
  deleteMatterRecordableEvent,

  downloadActivityHistory,
  createPdfBundle,
  printActivityHistory,
  exportActivityHistory,
  validateCopyActivityHistory,
  copyActivityHistory,
  validateMoveActivityHistory,
  moveActivityHistory,
  validateConvertActivityHistoryEmailFilesToEmails,
  convertActivityHistoryEmailFilesToEmails,
  validateShareActivityHistory,
  shareActivityHistory,

  getMatterDisbursements,
  getMatterDisbursementsForInvoice,
  getUninvoicedMatterDisbursements,
  getMatterDisbursementAccounts,
  getMatterDisbursementsSummaryInfo,
  getMatterDisbursementById,
  getMatterDisbursementPayments,
  createMatterDisbursement,
  updateMatterDisbursement,
  updateMatterDisbursementWriteOff,
  deleteMatterDisbursementWriteOff,
  printMatterDisbursements,
  downloadMatterDisbursements,
  deleteMatterDisbursement,

  getMatterIncidentalExpenses,
  getMatterIncidentalExpensesForLte,
  getUninvoicedMatterIncidentalExpenses,
  getMatterIncidentalExpensesForRecordableEvent,
  getMatterIncidentalExpensesForRecordableEventSummary,
  getMatterIncidentalExpensesForInvoice,
  getMatterIncidentalExpensesSummaryInfo,
  getLteIncidentalExpensesSummaryInfo,
  getMatterIncidentalExpenseById,
  createMatterIncidentalExpenses,
  updateMatterIncidentalExpense,
  updateMatterIncidentalExpenseWriteOff,
  deleteMatterIncidentalExpenseWriteOff,
  linkMatterIncidentalExpense,
  unlinkMatterIncidentalExpenses,
  printMatterIncidentalExpenses,
  downloadMatterIncidentalExpenses,
  deleteMatterIncidentalExpense,

  getMatterParticipatingEntitiesSummary,
  getMatterClientsSummary,
  getMatterDefaultClient,
  getMatterInvoicingPartiesSummary,
  getDefaultMatterInvoicingParty,
  getMatterClientLedgerEntries,
  getMatterClientLedgerSummaryInfo,
  getMatterClientLedgerBalanceSummaries,

  getAccountLedgerTransactionById,
  getAccountLedgerTransactionEntriesForTransaction,
  getAccountLedgerTransactionEntryById,
  updateAccountLedgerTransactionEntry,

  getEmailAttachmentsSuggestions,
  addEmailAttachments,

  getMatterInvoiceStatuses,
  getDraftMatterInvoice,
  getMatterInvoiceById,
  createMatterInvoice,
  createNilMatterInvoice,
  updateMatterInvoice,
  updateMatterInvoiceWriteOff,
  deleteMatterInvoiceWriteOff,
  updateMatterInvoiceAbatement,
  deleteMatterInvoiceAbatement,
  markMatterInvoiceAsDraft,
  changeMatterInvoiceShowOnMatterSummary,
  createMatterInvoiceDocument,
  createMatterInvoiceReceipt,
  deleteMatterInvoice,

  getMatterParticipatingEntitiesForInvoice,
  getMatterParticipatingEntitiesForInvoiceSummary,

  getMatterInvoicePayments,
  getMatterInvoicePaymentById,
  createMatterInvoicePayment,
  deleteMatterInvoicePayment,

  getMatterReceiveMoneyAccounts,
  getReceiveMoneyByTypes,
  getReceiveMoneyReasons,
  getMatterReceiveMoneyById,
  createMatterReceiveMoney,
  updateMatterReceiveMoney,
  deleteMatterReceiveMoney,

  getMatterReturnMoneyAccounts,
  getReturnMoneyByTypes,
  getReturnMoneyReasons,
  getMatterReturnMoneyById,
  createMatterReturnMoney,
  updateMatterReturnMoney,
  deleteMatterReturnMoney,

  getFundsTransferTypes,
  getMatterFundsTransferById,
  createMatterFundsTransfer,
  updateMatterFundsTransfer,
  deleteMatterFundsTransfer,

  getMatterClientAccountBalances,
  getEventAttendeeSuggestions,

  getMatterDashboardEvents,
  getMatterDashboardEvent,
  getCaseManager,
  getDiaryManager,

  createMatterDocument,

  flagMatterActivityHistory,
  unflagMatterActivityHistory,
  increasePriorityMatterActivityHistory,
  decreasePriorityMatterActivityHistory,
  increasePriorityMatter,
  decreasePriorityMatter,
};
