import { CreateOrUpdateLawPageTradingEntityModel } from "models/create/CreateOrUpdateLawPageTradingEntityModel";
import { CreateOrUpdateLteAddressModel } from "models/create/CreateOrUpdateLteAddressModel";
import { CreateOrUpdateLteBankAccountModel } from "models/create/CreateOrUpdateLteBankAccountModel";
import { CreateOrUpdateLteBranchModel } from "models/create/CreateOrUpdateLteBranchModel";
import { CreateOrUpdateLteDepartmentModel } from "models/create/CreateOrUpdateLteDepartmentModel";
import { CreateOrUpdateLtePhoneNumberModel } from "models/create/CreateOrUpdateLtePhoneNumberModel";
import { CreateOrUpdateLteTeamModel } from "models/create/CreateOrUpdateLteTeamModel";
import { UpdateLteStorageSettingsModel } from "models/update/UpdateLteStorageSettingsModel";
import { ChartOfAccountsLedgerEntryModel } from "models/view/ChartOfAccountsLedgerEntryModel";
import { ChartOfAccountsBalanceSummaryModel } from "models/view/ChartOfAccountsBalanceSummaryModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import { LteAddressModel } from "models/view/LteAddressModel";
import { LteBankAccountModel } from "models/view/LteBankAccountModel";
import { LteBranchModel } from "models/view/LteBranchModel";
import { LteDepartmentModel } from "models/view/LteDepartmentModel";
import { LtePhoneNumberModel } from "models/view/LtePhoneNumberModel";
import { LteStorageSettingsModel } from "models/view/LteStorageSettingsModel";
import { LteTeamModel } from "models/view/LteTeamModel";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import { ValidateFieldModel } from "models/view/ValidateFieldModel";
import { del, get, post, put, setHeader } from "utils/request";
import { UpdateLteCalendarSettingsModel } from "models/update/UpdateLteCalendarSettingsModel";
import { LteCalendarSettingsModel } from "models/view/LteCalendarSettingsModel";
import { CalendarEventModel } from "models/CalendarEventModel";
import moment from "moment";
import { CreateOrUpdateCalendarEventModel } from "models/create/CreateOrUpdateCalendarEventModel";
import { UpdateLteGeneralSettingsModel } from "models/update/UpdateLteGeneralSettingsModel";
import { LteGeneralSettingsModel } from "models/view/LteGeneralSettingsModel";
import { CreatableDropDownOptionModel } from "models/view/CreatableDropDownOptionModel";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { PaymentModel } from "models/view/PaymentModel";
import { MatterDisbursementPaymentModel } from "models/view/MatterDisbursementPaymentModel";
import { CreatePaymentModel } from "models/create/CreatePaymentModel";
import { FreeBusyScheduleModel } from "models/view/FreeBusyScheduleModel";
import { FreeBusyAttendeeCheckModel } from "models/create/FreeBusyAttendeeCheckModel";
import { FreeBusyAvailabilityViewModel } from "models/view/FreeBusyAttendeeAvailabilityViewModel";
import { MeetingTimeSuggestedTimeModel } from "models/view/MeetingTimeSuggestedTimeModel";
import { DropDownOptionWithChildrenModel } from "models/view/DropDownOptionWithChildrenModel";
import { DiaryManagerCalendarEventModel } from "models/DiaryManagerCalendarEventModel";
import { LteFinancialYearModel } from "models/view/LteFinancialYearModel";
import { AccountBalanceSummaryModel } from "models/view/AccountBalanceSummaryModel";
import { DelegatedLTEModel } from "models/view/DelegatedLTEModel";
import { CreateOrUpdateDelegatedLTEModel } from "models/create/CreateOrUpdateDelegatedLTEModel";

const getLtes = async (): Promise<any> => {
  const response = await get<LawPageTradingEntityModel[]>("/management/lte");
  return response;
}

const getLtesSummary = async (ignoreLTEId?: string): Promise<any> => {
  let url = '/management/lte/summary';
  if(ignoreLTEId){
    url += `?ignoreLTEId=${ignoreLTEId}`;
  }

  const response = await get<DropDownOptionModel[]>(url);
  return response;
}

const getLteSummaryForDiaryManagerDropdown = async (userId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/user/${userId}/diaryManageableLte/summary`);
  return response;
}

const getLteById = async (id: string): Promise<any> => {
  const response = await get<LawPageTradingEntityModel>(`/management/lte/${id}`);
  return response;
}

const getDiaryManagerEvents = async (startDate: string, endDate: string, userTimeZone?: string): Promise<any> => {
  let url = `/management/diaryManager/appointments?startDate=${startDate}&endDate=${endDate}`;
  if(userTimeZone){
    url += `&userTimeZone=${encodeURIComponent(userTimeZone)}`;
  }

  const response = await get<DiaryManagerCalendarEventModel[]>(url);
  return response;
}

const getDiaryManagerEvent = async (lteId: string, graphAppointmentId: string): Promise<any> => {
  const response = await get<CalendarEventModel[]>(`/management/lte/${lteId}/diaryManager/appointments/${graphAppointmentId}`);
  return response;
}

const getFreeBusySchedule = async (startDate: string, endDate: string, availabilityViewInterval?: string, 
  userTimeZone?: string, onlyWorkingHours: boolean = false, excludeWeekends: boolean = false): Promise<any> => {

  let url = `/management/calendar/freeBusySchedule?startDate=${startDate}&endDate=${endDate}`;
  if(availabilityViewInterval) {
    url += `&availabilityViewInterval=${availabilityViewInterval}`;
  }
  if(userTimeZone){
    url += `&userTimeZone=${encodeURIComponent(userTimeZone)}`;
  }
  if(onlyWorkingHours){
    url += `&onlyWorkingHours=${onlyWorkingHours}`;
  }
  if(excludeWeekends){
    url += `&excludeWeekends=${excludeWeekends}`;
  }
  const response = await get<FreeBusyScheduleModel[]>(url);
  return response;
}

const getLawPageRooms = async (lteId: string): Promise<any> => {
  let url = `/management/lte/${lteId}/lawPageRooms/summary`;
  const response = await get<FreeBusyScheduleModel[]>(url);
  return response;
}

const getAvailableLtesForDiaryManagerFilter = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/diaryManager/userLtes`);
  return response;
}

const getLteStorageSettingsById = async (id: string): Promise<any> => {
  const response = await get<LteStorageSettingsModel>(`/management/lte/${id}/storageSettings`);
  return response;
}

const getLteCalendarSettingsById = async (id: string): Promise<any> => {
  const response = await get<LteCalendarSettingsModel>(`/management/lte/${id}/calendarSettings`);
  return response;
}

const getLteGeneralSettingsById = async (id: string): Promise<any> => {
  const response = await get<LteGeneralSettingsModel>(`/management/lte/${id}/generalSettings`);
  return response;
}

const getLteCurrentFinancialYear = async (): Promise<any> => {
  const response = await get<LteFinancialYearModel>(`/management/lte/currentFinancialYear`);
  return response;
}

const getLteTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/lteType/summary');
  return response;
}

const getLteBankAccounts = async (id: string): Promise<any> => {
  const response = await get<LteBankAccountModel[]>(`/management/lte/${id}/lteBankAccount`);
  return response;
}

const getLteBankAccount = async (bankId: string): Promise<any> => {
  const response = await get<LteBankAccountModel>(`/management/lteBankAccount/${bankId}`);
  return response;
}

const getLteReceivableBankAccount = async (lteId: string): Promise<any> => {
  const response = await get<LteBankAccountModel | undefined>(`/management/lte/${lteId}/receivableBankAccount`);
  return response;
}

const getLtePayableBankAccount = async (lteId: string): Promise<any> => {
  const response = await get<LteBankAccountModel | undefined>(`/management/lte/${lteId}/payableBankAccount`);
  return response;
}

const getLteBranch = async (id: string): Promise<any> => {
  const response = await get<LteBranchModel>(`/management/lteBranch/${id}`);
  return response;
}

const getLteBranches = async (id: string): Promise<any> => {
  const response = await get<LteBranchModel[]>(`/management/lte/${id}/lteBranch`);
  return response;
}

const getLteBranchesWithDepartments = async (id: string): Promise<any> => {
  const response = await get<LteBranchModel[]>(`/management/lte/${id}/lteBranch/summary/departmentsOnly`);
  return response;
}

const getLteBranchesSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lteBranch/summary`);
  return response;
}

const getLteBranchesWithDepartmentsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionWithChildrenModel[]>(`/management/lteBranch/summary/departments`);
  return response;
}

const getLteBranchesWithDepartmentsAndTeamsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionWithChildrenModel[]>(`/management/lteBranch/summary/teams`);
  return response;
}

const getLteBranchesWithDepartmentsAndTeams = async (id: string): Promise<any> => {
  const response = await get<DropDownOptionWithChildrenModel[]>(`/management/lte/${id}/lteBranch/summary`);
  return response;
}

const getLteDepartments = async (id: string): Promise<any> => {
  const response = await get<LteDepartmentModel[]>(`/management/lte/${id}/lteDepartment`);
  return response;
}

const getLteDepartmentsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lteDepartment/summary`);
  return response;
}

const getLtePhoneNumbers = async (lteId: string): Promise<any> => {
  const response = await get<LtePhoneNumberModel[]>(`/management/ltePhoneNumber?lteId=${lteId}`);
  return response;
}

const getLteAddresses = async (lteId: string): Promise<any> => {
  const response = await get<LteAddressModel[]>(`/management/lteAddress?lteId=${lteId}`);
  return response;
}

const getPreferredLteAddress = async (lteId: string): Promise<any> => {
  const response = await get<LteAddressModel | undefined>(`/management/lte/${lteId}/address/preferred`);
  return response;
}

const getPreferredLtePhoneNumber = async (lteId: string): Promise<any> => {
  const response = await get<LtePhoneNumberModel | undefined>(`/management/lte/${lteId}/phoneNumber/preferred`);
  return response;
}

const getLtePhoneNumberById = async (lteId: string, phoneNumberId: string): Promise<any> => {
  const response = await get<LtePhoneNumberModel>(`/management/ltePhoneNumber/ltePhoneNumberById?lteId=${lteId}&phoneNumberId=${phoneNumberId}`);
  return response;
}

const getLteAddressById = async (lteId: string, addressId: string): Promise<any> => {
  const response = await get<LteAddressModel>(`/management/lteAddress/lteAddressById?lteId=${lteId}&addressId=${addressId}`);
  return response;
}

const getLteDepartment = async (id: string): Promise<any> => {
  const response = await get<LteDepartmentModel[]>(`/management/lteDepartment/${id}`);
  return response;
}

const getLteDepartmentsFromBranch = async (id: string): Promise<any> => {
  const response = await get<LteDepartmentModel[]>(`/management/lteBranch/${id}/lteDepartment`);
  return response;
}

const getLteTeams = async (id: string): Promise<any> => {
  const response = await get<LteTeamModel[]>(`/management/lte/${id}/lteTeam`);
  return response;
}

const getLteTeamsSummary = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lteTeam/summary`);
  return response;
}

const getLteTeam = async (id: string): Promise<any> => {
  const response = await get<LteTeamModel[]>(`/management/lteTeam/${id}`);
  return response;
}

const getCalendarView = async (startDateTime: string, endDateTime: string, userTimeZone?: string) => {
  let url = `/management/user/getCalendar?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
  if(userTimeZone){
    url += `&userTimeZone=${encodeURIComponent(userTimeZone)}`;
  }

  const response = await get<CalendarEventModel[]>(url);
  return response;
}

const getCalendarRecurrenceTypes = async (): Promise<any> => {
  const response = await get<CalendarEventModel[]>("/management/calendar/recurrencePatternType/summary");
  return response;
}

const validateLteLegalName = async (name: string, lteId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/lte/legalName/validate`, {
    "value": name,
    "currentId": lteId
  });
  return response;
}

const validateLteCode = async (name: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/lte/code/validate`, {
    "value": name
  });
  return response;
}

const getLteTeamsFromDepartment = async (id: string): Promise<any> => {
  const response = await get<LteTeamModel[]>(`/management/lteDepartment/${id}/lteTeam`);
  return response;
}

const lteHasMatters = async (id: string): Promise<any> => {
  const response = await get<boolean>(`/management/lte/${id}/hasMatters`);
  return response;
}

const changeLteStatus = async (id: string): Promise<any> => {
  const response = await post(`/management/lte/${id}/changeStatus`, undefined);
  return response;
}

const addCalendarEvent = async (data: CreateOrUpdateCalendarEventModel): Promise<any> => {
  const requestBody = {
    ...data,
    startDate: moment(data.startDate).format(),
    endDate: moment(data.endDate).format(),
  }
  
  const response = await post<any, string>("/management/calendar", requestBody);
  return response;
}

const updateDiaryManagerEvent = async (lteId: string, eventId: string, data: CreateOrUpdateCalendarEventModel): Promise<any> => {
  const requestBody = {
    ...data,
    startDate: moment(data.startDate).format(),
    endDate: moment(data.endDate).format(),
  }

  const response = await put<any, string>(`/management/lte/${lteId}/diaryManager/appointments/${eventId}`, requestBody);
  return response;
}

const checkEventAttendees = async (data: FreeBusyAttendeeCheckModel): Promise<any> => {
  const response = await post<FreeBusyAttendeeCheckModel, FreeBusyAvailabilityViewModel>("/management/calendar/freeBusyAttendeesCheck", data);
  return response;
}

const getMeetingTimes = async (data: FreeBusyAttendeeCheckModel, numberOfSuggestions?: number): Promise<any> => {
  let url: string = `/management/calendar/meetingTimeSuggestions`;
  if(numberOfSuggestions) {
    url += `?numberOfSuggestions=${numberOfSuggestions}`;
  }
  const response = await post<FreeBusyAttendeeCheckModel, MeetingTimeSuggestedTimeModel>(url, data);
  return response;
}

const addLte = async (data: CreateOrUpdateLawPageTradingEntityModel): Promise<any> => {
  const response = await post<CreateOrUpdateLawPageTradingEntityModel, LawPageTradingEntityModel>("/management/lte", data);
  return response;
}

const addLteBankAccount = async (data: CreateOrUpdateLteBankAccountModel): Promise<any> => {
  const response = await post<CreateOrUpdateLteBankAccountModel, LteBankAccountModel>("/management/lteBankAccount", data);
  return response;
}

const addLteBranch = async (data: CreateOrUpdateLteBranchModel): Promise<any> => {
  const response = await post<CreateOrUpdateLteBranchModel, LteBranchModel>("/management/lteBranch", data);
  return response;
}

const addLteDepartment = async (data: CreateOrUpdateLteDepartmentModel): Promise<any> => {
  const response = await post<CreateOrUpdateLteDepartmentModel, LteDepartmentModel>("/management/lteDepartment", data);
  return response;
}

const addLtePhoneNumber = async (lteId: string, data: CreateOrUpdateLtePhoneNumberModel): Promise<any> => {
  const response = await post<CreateOrUpdateLtePhoneNumberModel, LtePhoneNumberModel>(`/management/ltePhoneNumber?lteId=${lteId}`, data);
  return response;
}

const addLteAddress = async (lteId: string, data: CreateOrUpdateLteAddressModel): Promise<any> => {
  const response = await post<CreateOrUpdateLteAddressModel, LteAddressModel>(`/management/lteAddress?lteId=${lteId}`, data);
  return response;
}

const addLteTeam = async (data: CreateOrUpdateLteTeamModel): Promise<any> => {
  const response = await post<CreateOrUpdateLteTeamModel, LteTeamModel>("/management/lteTeam", data);
  return response;
}

const updateLte = async (id: string, data: CreateOrUpdateLawPageTradingEntityModel): Promise<any> => {
  const response = await put<CreateOrUpdateLawPageTradingEntityModel, LawPageTradingEntityModel>(`/management/lte/${id}`, data);
  return response;
}

const updateLteStorageSettings = async (id: string, data: UpdateLteStorageSettingsModel): Promise<any> => {
  const response = await put<UpdateLteStorageSettingsModel, LteStorageSettingsModel>(`/management/lte/${id}/storageSettings`, data);
  return response;
}

const updateLteCalendarSettings = async (id: string, data: UpdateLteCalendarSettingsModel): Promise<any> => {
  const response = await put<UpdateLteCalendarSettingsModel, LteCalendarSettingsModel>(`/management/lte/${id}/calendarSettings`, data);
  return response;
}

const updateLteGeneralSettings = async (id: string, data: UpdateLteGeneralSettingsModel): Promise<any> => {
  const response = await put<UpdateLteGeneralSettingsModel, LteGeneralSettingsModel>(`/management/lte/${id}/generalSettings`, data);
  return response;
}

const updateLteBankAccount = async (id: string, data: CreateOrUpdateLteBankAccountModel): Promise<any> => {
  const response = await put<CreateOrUpdateLteBankAccountModel, LteBankAccountModel>(`/management/lteBankAccount/${id}`, data);
  return response;
}

const updateLteBranch = async (id: string, data: CreateOrUpdateLteBranchModel): Promise<any> => {
  const response = await put<CreateOrUpdateLteBranchModel, LteBranchModel>(`/management/lteBranch/${id}`, data);
  return response;
}

const updateLteDepartment = async (id: string, data: CreateOrUpdateLteDepartmentModel): Promise<any> => {
  const response = await put<CreateOrUpdateLteDepartmentModel, LteDepartmentModel>(`/management/lteDepartment/${id}`, data);
  return response;
}

const updateLtePhoneNumber = async (phoneNumberId: string, lteId: string, data: CreateOrUpdateLtePhoneNumberModel): Promise<any> => {
  const response = await put<CreateOrUpdateLtePhoneNumberModel, LtePhoneNumberModel>(`/management/ltePhoneNumber?lteId=${lteId}&phoneNumberId=${phoneNumberId}`, data);
  return response;
}

const updateLteAddress = async (addressId: string, lteId: string, data: CreateOrUpdateLteAddressModel): Promise<any> => {
  const response = await put<CreateOrUpdateLteAddressModel, LteAddressModel>(`/management/lteAddress?lteId=${lteId}&addressId=${addressId}`, data);
  return response;
}

const updateLteTeam = async (id: string, data: CreateOrUpdateLteTeamModel): Promise<any> => {
  const response = await put<CreateOrUpdateLteTeamModel, LteTeamModel>(`/management/lteTeam/${id}`, data);
  return response;
}

const deleteLte = async (id: string): Promise<any> => {
  const response = await del(`management/lte/${id}`);
  return response;
}

const deleteLteBankAccount = async (id: string): Promise<any> => {
  const response = await del(`management/lteBankAccount/${id}`);
  return response;
}

const deleteLteBranch = async (id: string): Promise<any> => {
  const response = await del(`management/lteBranch/${id}`);
  return response;
}

const deleteLteDepartment = async (id: string): Promise<any> => {
  const response = await del(`management/lteDepartment/${id}`);
  return response;
}

const deleteLtePhoneNumber = async (lteId: string, phoneNumberId: string, shouldDeleteAllEntities: boolean): Promise<any> => {
  const response = await del(`management/ltePhoneNumber?lteId=${lteId}&phoneNumberId=${phoneNumberId}&shouldDeleteAllEntities=${shouldDeleteAllEntities}`);
  return response;
}

const deleteLteAddress = async (lteId: string, addressId: string, shouldDeleteAllEntities: boolean): Promise<any> => {
  const response = await del(`management/lteAddress?lteId=${lteId}&addressId=${addressId}&shouldDeleteAllEntities=${shouldDeleteAllEntities}`);
  return response;
}

const deleteLteTeam = async (id: string): Promise<any> => {
  const response = await del(`management/lteTeam/${id}`);
  return response;
}

const getChartOfAccountsLedgerEntries = async (accountId: string, startDate?: Date, endDate?: Date): Promise<any> => {
  let url = `/management/account/${accountId}/chartOfAccounts`;
  let queryParams: string[] = [];

  if(startDate){
    queryParams.push(`startDate=${startDate.toISOString()}`);
  }
  if(endDate){
    queryParams.push(`endDate=${endDate.toISOString()}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<ChartOfAccountsLedgerEntryModel[]>(url);
  return response;
}

const getChartOfAccountsSummaries = async (): Promise<any> => {
  const response = await get<ChartOfAccountsBalanceSummaryModel[]>(`/management/chartOfAccounts`);
  return response;
}

const getAccountBalanceSummary = async (accountId: string, startDate?: Date, endDate?: Date): Promise<any> => {
  let url = `/management/account/${accountId}/balance`;
  let queryParams: string[] = [];

  if(startDate){
    queryParams.push(`startDate=${startDate.toISOString()}`);
  }
  if(endDate){
    queryParams.push(`endDate=${endDate.toISOString()}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<AccountBalanceSummaryModel[]>(url);
  return response;
}

const getMatterInvoicesForLte = async (matterInvoiceStatusIds?: string[], startDate?: Date, endDate?: Date): Promise<any> => {
  let url = `/management/invoice`;
  let queryParams: string[] = [];

  if(matterInvoiceStatusIds && matterInvoiceStatusIds.length > 0){
    matterInvoiceStatusIds.forEach(function (statusId) {
      queryParams.push(`statusIds=${statusId}`);
    });
  }
  if(startDate){
    queryParams.push(`startDate=${startDate.toISOString()}`);
  }
  if(endDate){
    queryParams.push(`endDate=${endDate.toISOString()}`);
  }

  if(queryParams.length > 0){
    url += `?${queryParams.join('&')}`;
  }

  const response = await get<MatterInvoiceModel[]>(url);
  return response;
}

const getInvoiceDueDateSettingTypes = async (): Promise<any> => {
  const response = await get<DropDownOptionModel[]>('/management/invoiceDueDateSettingType/summary');
  return response;
}

const getInvoiceDisplayNameSuggestions = async (): Promise<any> => {
  const response = await get<CreatableDropDownOptionModel[]>(`/management/invoiceDisplayName/suggestion`);
  return response;
}

const getMatterDisbursementsForLTE = async (onlyUnpaid: boolean = false): Promise<any> => {
  let url = `/management/matterDisbursement`;
  if(onlyUnpaid){
    url += `?onlyUnpaid=${onlyUnpaid}`;
  }
  const response = await get<MatterDisbursementModel[]>(url);
  return response;
}

const getPaymentById = async (paymentId: string): Promise<any> => {
  const response = await get<PaymentModel>(`/management/payment/${paymentId}`);
  return response;
}

const getPaymentMatterDisbursementPayments = async (paymentId: string): Promise<any> => {
  const response = await get<MatterDisbursementPaymentModel[]>(`/management/payment/${paymentId}/disbursementPayment`);
  return response;
}

const addPayment = async (model: CreatePaymentModel, msalAccessToken: string): Promise<any> => {
  setHeader("X-Authorization", msalAccessToken);
  const response = await post<CreatePaymentModel, any>(`/management/payment`, model);
  return response;
}

const deletePayment = async (paymentId: string): Promise<any> => {
  await del(`/management/payment/${paymentId}`);
}

const getDelegatedFromLTEs = async (lteId: string): Promise<any> => {
  const response = await get<DelegatedLTEModel[]>(`/management/delegatedLTE/from/${lteId}`);
  return response;
}

const getDelegatedFromLTEsSummary = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/delegatedLTE/from/${lteId}/summary`);
  return response;
}

const getDelegatedToLTEsSummary = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/delegatedLTE/to/${lteId}/summary`);
  return response;
}

const getDelegatedLTEById = async (delegatedLTEId: string): Promise<any> => {
   const response = await get<DelegatedLTEModel>(`/management/delegatedLTE/${delegatedLTEId}`);
  return response;
}

const validateDelegatedLTEToLTE = async (lteId: string, toLTEId: string, delegatedLTEId?: string): Promise<any> => {
  const response = await post<ValidateFieldModel, boolean>(`/management/delegatedLTE/toLTE/validate`, {
    "value": toLTEId,
    "currentId": delegatedLTEId,
    "parentResourceId": lteId
  });
  return response;
}

const addDelegatedLTE = async (model: CreateOrUpdateDelegatedLTEModel): Promise<any> => {
  const response = await post<CreateOrUpdateDelegatedLTEModel, DelegatedLTEModel>('/management/delegatedLTE', model);
  return response;
}

const updateDelegatedLTE = async (delegatedLTEId: string, model: CreateOrUpdateDelegatedLTEModel): Promise<any> => {
  var response = await put<CreateOrUpdateDelegatedLTEModel, DelegatedLTEModel>(`/management/delegatedLTE/${delegatedLTEId}`, model);
  return response;
}

const deleteDelegatedLTE = async (delegatedLTEId: string): Promise<any> => {
  await del(`/management/delegatedLTE/${delegatedLTEId}`);
}

export { 
  getLtes,
  getLtesSummary,
  getLteSummaryForDiaryManagerDropdown,
  getLteById,
  getLteStorageSettingsById,
  getLteCalendarSettingsById,
  getLteGeneralSettingsById,
  getLteCurrentFinancialYear,
  getLteTypes,
  getLteBankAccounts,
  getLteBankAccount,
  getLteReceivableBankAccount,
  getLtePayableBankAccount,
  getLteBranch,
  getLteBranches,
  getLteBranchesWithDepartments,
  getLteBranchesSummary,
  getLteBranchesWithDepartmentsSummary,
  getLteBranchesWithDepartmentsAndTeamsSummary,
  getLteBranchesWithDepartmentsAndTeams,
  getLteDepartments,
  getLteDepartmentsSummary,
  getLteDepartment,
  getLteDepartmentsFromBranch,
  getLteTeams,
  getLteTeamsSummary,
  getLteTeam,
  getCalendarView,
  getCalendarRecurrenceTypes,
  getLteTeamsFromDepartment,
  lteHasMatters,
  getLtePhoneNumbers,
  getLteAddresses,
  getPreferredLtePhoneNumber,
  getPreferredLteAddress,
  getLtePhoneNumberById,
  getLteAddressById,
  changeLteStatus,
  validateLteLegalName,
  validateLteCode,
  addCalendarEvent,
  updateDiaryManagerEvent,
  checkEventAttendees,
  getMeetingTimes,
  addLte,
  addLteBankAccount,
  addLteBranch,
  addLteDepartment,
  addLteTeam,
  addLtePhoneNumber,
  addLteAddress,
  updateLte,
  updateLteStorageSettings,
  updateLteCalendarSettings,
  updateLteGeneralSettings,
  updateLteBankAccount,
  updateLteBranch,
  updateLteDepartment,
  updateLteTeam,
  updateLtePhoneNumber,
  updateLteAddress,
  deleteLte,
  deleteLteBankAccount,
  deleteLteBranch,
  deleteLteDepartment,
  deleteLteTeam,
  deleteLtePhoneNumber,
  deleteLteAddress,
  getChartOfAccountsLedgerEntries,
  getChartOfAccountsSummaries,
  getAccountBalanceSummary,

  getMatterInvoicesForLte,
  getInvoiceDueDateSettingTypes,
  getInvoiceDisplayNameSuggestions,

  getMatterDisbursementsForLTE,
  getPaymentById,
  getPaymentMatterDisbursementPayments,
  addPayment,
  deletePayment,

  getDiaryManagerEvents,
  getDiaryManagerEvent,
  getFreeBusySchedule,
  getLawPageRooms,
  getAvailableLtesForDiaryManagerFilter,

  getDelegatedFromLTEs,
  getDelegatedFromLTEsSummary,
  getDelegatedToLTEsSummary,
  getDelegatedLTEById,
  validateDelegatedLTEToLTE,
  addDelegatedLTE,
  updateDelegatedLTE,
  deleteDelegatedLTE
};
