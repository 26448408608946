import { useEffect, useState } from "react";
import { Form, Button, Dropdown } from 'react-bootstrap';
import { MdSort, MdFilterAlt, MdAdd, MdClear } from 'react-icons/md';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import Loader from "components/Loader";
import './style.scss';
import { SortOrderTypes } from "enums/SortOrderTypes";
import { BsSortAlphaDown, BsSortAlphaDownAlt } from "react-icons/bs";
import { GridQuickFilterButtonDataModel } from "models/view/GridQuickFilterButtonDataModel";

type Props = {
  title: string;
  className?: string;
  children: React.ReactNode[];
  searchBar?: boolean;
  isLoading?: boolean;
  genericErrors?: any;
  onSearch?: (searchText: string) => void;
  onSort?: (sortOrder: SortOrderTypes) => void;
  onFilter?: () => void;
  onAdd?: () => void;
  showAddButton?: boolean,
  quickFilters?: GridQuickFilterButtonDataModel[],
}

function QuickSearchPanel(props: Props) {
  const [genericErrors, setGenericErrors] = useState(props.genericErrors ?? null);
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading ?? false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortOrderTypes>(SortOrderTypes.None);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedFilterName, setSelectedFilterName] = useState<string | undefined>(undefined);

  const toggleQuickSearchPanel = () => {
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  }

  const onChangeSearch = (e: any) => {
    setSearchText(e.target.value);

    if(props.onSearch) {
      props.onSearch!(e.target.value);
    }
  }

  const clearSearch = () => {
    setSearchText('');

    if(props.onSearch) {
      props.onSearch!('');
    }
  }

  const toggleSortOrder = () => {
    const newSortOrder: number = (sortOrder + 1) % 3;
    setSortOrder(newSortOrder);
    if(props.onSort) {
      props.onSort!(newSortOrder);
    }
  }

  const getSortOrderIcon = () => {
    switch(sortOrder) {
      case SortOrderTypes.None: {
        return <MdSort />;
      }
      case SortOrderTypes.Ascending: {
        return <BsSortAlphaDown />;
      }
      case SortOrderTypes.Descending: {
        return <BsSortAlphaDownAlt />;
      }
    }
  };

  const onQuickFilterSelect = (filter: GridQuickFilterButtonDataModel) => {
    setSearchText('');
    setSelectedFilterName(filter.text);
    filter.callback();
  }

  return(
    <div className={`lp-quick-search-panel ${props.className ?? ''}${isExpanded ? ' expanded' : ' collapsed'}`} onClick={!isExpanded ? toggleQuickSearchPanel : undefined}>
      <div className="lp-quick-search-header">
        <div className="lp-quick-search-title">
          {props.title}
        </div>

        {(props.onSort || props.onFilter || (props.onAdd && props.showAddButton)) && (
          <div className="lp-quick-search-actions">
            {props.onSort && 
              <Button variant="secondary-400" className={`${props.children ? 'btn-icon' : 'btn-icon disabled'}`} onClick={toggleSortOrder}>
                {getSortOrderIcon()}
              </Button>
            }
            {/*props.onFilter &&
              <Button variant="secondary-400" className={`${props.children ? 'btn-icon' : 'btn-icon disabled'}`} onClick={() => props.onFilter!()}>
                <MdFilterAlt />
              </Button>
            */}
            {props.quickFilters && props.quickFilters.length > 0 && 
              <Dropdown>
                <Dropdown.Toggle variant='secondary-400' className='btn btn-icon'>
                  <MdFilterAlt />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    props.quickFilters.map((el: GridQuickFilterButtonDataModel, index: number) => (
                      <Dropdown.Item
                        key={`quick-filters-option-${index}`}
                        onClick={() => onQuickFilterSelect(el)}
                      >
                        {el.text}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
            }
            {props.onAdd && props.showAddButton &&
              <Button variant="success" className="btn-icon" onClick={() => props.onAdd!()}>
                <MdAdd />
              </Button>
            }
          </div>
        )}
      </div>

      {selectedFilterName && (
        <div className="lp-quick-search-selected-filter">
          Filter: {selectedFilterName}
        </div>
      )}

      {props.searchBar && props.onSearch && (
        <div className="lp-quick-search-searchbar">
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={onChangeSearch}
          />
          {searchText.length > 0 && 
            <Button variant="secondary-400" className={`${props.children ? 'btn-icon' : 'btn-icon disabled'}`} onClick={clearSearch}>
              <MdClear />
            </Button>
          }
        </div>
      )}

      {props.children && props.children.length > 0 ? (
        <div className="lp-quick-search-list">
          {isLoading && <Loader inlineLoader />}
          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          {props.children}
        </div>
        ) : (
          <div className="lp-empty">
            <div className="image"></div>
            <div className="text">No data found!</div>
          </div>
        )
      }
      
      <div className="lp-quick-search-pagination">Pagination</div>

      <div className="lp-quick-search-panel-toggle" onClick={toggleQuickSearchPanel}>
        {isExpanded ? <HiChevronLeft /> : <HiChevronRight />}
      </div>
    </div>
  );
}

export default QuickSearchPanel;
