import {
  getAppointments,
  sendAppointmentsForManualSaving,
} from 'actions/user';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import Loader from 'components/Loader/index';
import useSlidingPanelActions from 'actions/slidingPanel';
import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs';
import CustomSelect from 'components/Select/Select';
import { vestResolver } from '@hookform/resolvers/vest';
import { validationSuite } from './validationSuite';
import { Controller, useForm } from 'react-hook-form';
import { getOpenMattersSummary } from 'actions/matter';
import { getDateOnly } from "utils/date";
import DatePicker from "react-datepicker";
import { AppointmentForManualSavingModel } from 'models/view/AppointmentForManualSavingModel';

type Props = {
  matterId?: string;
  onSubmitCallback?: Function;
};

type ManualAddAppointmentModel = {
  matterId: string
};

export default function ManualAddAppointments(props: Props) {
  const [genericErrors, setGenericErrors] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [existingNextPage, setExistingNextPage] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<AppointmentForManualSavingModel[]>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('week').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf('week').toDate()
  );
  const slidingPanelActions = useSlidingPanelActions();

  const { handleSubmit, control, watch, formState: { errors } } = useForm<ManualAddAppointmentModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      matterId: props.matterId
    }
  });

  const onSubmit = handleSubmit((data) => submitEmails(data));

  async function submitEmails(data: ManualAddAppointmentModel) {
    if (selectedIds.length == 0) {
      setGenericErrors('Please select at least one appointment.');
      return;
    }

    setIsLoading(true);
    sendAppointmentsForManualSaving(
      data.matterId,
      selectedIds
    )
      .then((response) => {
        props.onSubmitCallback && props.onSubmitCallback();
        slidingPanelActions.clearSlidingPanel();
        setSelectedIds([]);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    var matterId = watch('matterId');
    if(matterId) {
      loadUserAppointments(matterId, pageNumber, startDate, endDate);
    }
  }, [pageNumber, watch('matterId'), startDate, endDate]);

  const loadUserAppointments = (matterId: string, page: number, startDate: Date, endDate: Date) => {
    setIsLoading(true);
    const offsetStartDate = startDate.getTimezoneOffset()
    startDate = new Date(startDate.getTime() - (offsetStartDate*60*1000));
    const offsetEndDate = endDate.getTimezoneOffset()
    endDate = new Date(endDate.getTime() - (offsetEndDate*60*1000));

    getAppointments(
      matterId,
      page.toString(),
      startDate.toISOString().split('T')[0],
      endDate.toISOString().split('T')[0]
    )
    .then((response) => {
      setExistingNextPage(response.data.existingNextPage);
      setAppointments(response.data.appointments);
      setGenericErrors(undefined);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const handlePageDown = () => {
    if (pageNumber > 0) {
      setIsLoading(true);
      setSelectedIds([]);
      setPageNumber(pageNumber - 1);
    }
  };

  const handlePageUp = () => {
    if (existingNextPage) {
      setIsLoading(true);
      setSelectedIds([]);
      setPageNumber(pageNumber + 1);
    }
  };

  const handleSelection = (graphResourceId: string) => {
    if (selectedIds?.some((x: string) => x == graphResourceId)) {
      setSelectedIds(selectedIds.filter((x: string) => x != graphResourceId));
    } else {
      setSelectedIds([...selectedIds, graphResourceId]);
    }
  };

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    setSelectedIds([]);
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="lp-emails-form">
        {!props.matterId &&
          <Form.Group className="lp-emails-matter" controlId="matterId">
            <Form.Label className="required">Matter</Form.Label>
            <Controller
              control={control}
              name={`matterId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterId?.message ? ' invalid' : ''}`}
                  endpointCall={getOpenMattersSummary}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterId?.message && (errors.matterId?.message)}
            </Form.Text>
          </Form.Group>
        }

        <div className="lp-emails-filter">
          <div className="lp-emails-filter-col">
            <Form.Label>From</Form.Label>
            <DatePicker 
              dateFormat={DateFormat.Datepicker}
              value={moment(startDate).format(DateFormat.Moment)}
              selected={startDate ? getDateOnly(startDate) : null}
              onChange={(date: Date) => {
                setStartDate(date);
                if(date > endDate) {
                  setEndDate(moment(date).add(1, 'week').toDate());
                }
              }}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
            />
          </div>
          <div className="lp-emails-filter-col">
            <Form.Label>To</Form.Label>
            <DatePicker 
              dateFormat={DateFormat.Datepicker}
              value={moment(endDate).format(DateFormat.Moment)}
              selected={endDate ? getDateOnly(endDate) : null}
              onChange={(date: Date) => setEndDate(date)}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
            />
          </div>
        </div>

        <div className={`lp-emails-list${(pageNumber != 0 || existingNextPage) ? ' with-pagination' : ''}`}>
          {appointments == undefined || appointments.length == 0 &&
            <div className="lp-empty appointments">
              <div className="image"></div>
              <div className="text">No appointments found!</div>
            </div>
          }

          {appointments &&
            appointments?.map((x: AppointmentForManualSavingModel, index: number) => (
              <React.Fragment key={index}>
                <div
                  key={index * (pageNumber + 1)}
                  onClick={x.existsOnMatterId ? () => {} : (() => handleSelection(x.graphId))}
                  className={`lp-email-item${
                    x.existsOnMatterId ? ' existing-email' : ''
                  }${
                    selectedIds.some((y: string) => y == x.graphId)
                      ? ' selected-email'
                      : ''
                  }${
                    x.isDraft ? ' draft-email' : ''
                  }`}
                >
                  <div className="lp-email-item-info">
                    {x.existsOnMatterId &&
                      <span className="lp-email-item-existing">{x.existsOnMatter?.fileNumber}</span>
                    }
                  </div>
                  <div className="lp-email-item-date">
                    {
                      x?.startDate
                        ? moment(x?.startDate).format(
                            DateFormat.MomentWithTime
                          )
                        : '—'
                    }
                    {' - '}
                    {
                      x?.endDate
                        ? moment(x?.endDate).format(
                            DateFormat.MomentWithTime
                          )
                        : '—'
                    }
                  </div>
                  <div className="lp-email-item-selection">
                    {selectedIds.some((y: string) => y == x.graphId) ? (
                      <span className="selected">
                        <BsCheckCircleFill />
                      </span>
                    ) : (
                      <span className="unselected">
                        <BsCheckCircle />
                      </span>
                    )}
                  </div>
                  <div className="lp-email-item-subject">
                    <strong>{x.title}</strong>
                  </div>
                  <div className="lp-email-item-recipients">
                    <div className="lp-email-item-appointment-label">
                      <span className="label">Attendees</span>
                      <span className="value">
                        {
                          x.requiredAttendees && x.requiredAttendees.length > 0
                            ? x.requiredAttendees.map((x, i) => <React.Fragment key={i}><span className="email">{x}</span></React.Fragment>)
                            : '—'
                        }
                        {
                          x.optionalAttendees && x.optionalAttendees.length > 0
                            && x.optionalAttendees.map((x, i) => <React.Fragment key={i}><span className="email">{x}</span></React.Fragment>)
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          }
        </div>

        {(pageNumber != 0 || existingNextPage) && (
          <div className="lp-emails-pagination">
            <Button
              onClick={handlePageDown}
              variant="secondary-400"
              disabled={pageNumber == 0}
            >
              Previous page
            </Button>

            <div className="lp-emails-current-page">
              Page
              {' ' + (pageNumber + 1)}
            </div>

            <Button
              onClick={handlePageUp}
              variant="secondary-400"
              disabled={!existingNextPage}
            >
              Next page
            </Button>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <Button type="submit" variant="success">
            Import
          </Button>
          <Button variant="secondary-400" onClick={cancelForm}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}
