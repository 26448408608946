import { cloneElement, ReactElement, useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { decreasePriorityMatterActivityHistory, deleteMatterFile, deleteMatterFilePermission, flagMatterActivityHistory, getMatterFileById, getMatterFilePermissions, increasePriorityMatterActivityHistory, unflagMatterActivityHistory } from "actions/matter";
import { MatterFileModel } from "models/view/MatterFileModel";
import BooleanField from "components/Fields/BooleanField";
import moment from "moment";
import { DateFormat } from "utils/constants";
import ListSlidingPanel from "components/List/ListSlidingPanel";
import { MatterFilePermissionsModel } from "models/view/MatterFilePermissionsModel";
import { formatFileSize, getDecreasedPriority, getIncreasedPriority, openUrlInNewtab } from "utils/misc";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { DeleteMatterFilePermissionModel } from "models/delete/DeleteMatterFilePermissionModel";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditMatterFileDetailsForm from "../EditMatterFileDetails/EditMatterFileDetailsForm";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import PreviewMatterFile from "../PreviewMatterFile/PreviewMatterFile";
import CustomTable from "components/CustomTable/CustomTable";
import { columnDefsFileHistoryCustomTable } from "./columnDefsFileHistoryCustomTable";
import { UserRoleNames } from "enums/UserRoleNames";
import usePageActions from "actions/page";
import { Priority } from "enums/Priority";
import { getPriority } from "utils/enums";

type Props = {
  matterId: string,
  fileId: string,
  activityHistoryId?: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
  isFlagged?: boolean,
  priority?: Priority,
}

function ViewMatterFile(props: Props){
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterFile, setMatterFile] = useState<MatterFileModel>();
  const [matterFilePermissions, setMatterFilePermissions] = useState<MatterFilePermissionsModel>();
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    setIsLoading(true);
    getMatterFileById(props.matterId, props.fileId).then( response => {
      setMatterFile(response.data);
      
      if(response.data?.isDeletedOnStorage == false){
        getMatterFilePermissions(props.matterId, props.fileId).then( getMatterFilePermissionsResponse => {
          setMatterFilePermissions(getMatterFilePermissionsResponse.data);
        }).catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        }).finally(() => {
          setIsLoading(false);
        });
      }
      else {
        setIsLoading(false);
      }
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setIsLoading(false);
    });
  }, [props.fileId]);

  // Set more buttons only after matterFile is loaded as some properties are needed for the buttons
  useEffect(() => {
    if (matterFile) {
      const canManagePdfFiles = matterFile.isInvoiceRelated || 
        (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
        loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
        loggedInUser.userRole?.name != UserRoleNames.Accountant &&
        loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
        !matterFile.isInvoiceRelated);

      if(canManagePdfFiles) {
        setMoreButtons();
        setEditButton();
      }
    }
  }, [matterFile, isFlagged, priority]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  }

  const setEditButton = () => {
    if (!props.isMatterClosed && !matterFile?.isDeletedOnStorage) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Matter File Details",
          children: <EditMatterFileDetailsForm
            matterId={props.matterId}
            fileId={props.fileId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setMoreButtons = () => {
    if (!props.isMatterClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        // Preview
        ...(!matterFile?.isDeletedOnStorage ? [{
          type: ActionButtonTypes.PreviewFile,
          callback: () => onClickPreview(),
        }] : []),
        // Taxonomies
        ...((props.hasTaxonomies && props.activityHistoryId) ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        // Open in new tab
        ...((!matterFile?.isDeletedOnStorage && matterFile?.webUrl) ? [{
          type: ActionButtonTypes.OpenFile,
          callback: () => openUrlInNewtab(matterFile?.webUrl)
        }] : []),
        // Flag
        ...(props.activityHistoryId && isFlagged !== undefined ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...(props.activityHistoryId && priority !== undefined ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        ...(!matterFile?.isDeletedOnStorage ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  }

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const onClickPreview = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        allowNavigation: true,
        title: "Preview Matter File",
        children: <PreviewMatterFile
          matterId={props.matterId}
          fileId={props.fileId!}
          activityHistoryId={props.activityHistoryId}
          isMatterClosed={props.isMatterClosed}
          hasTaxonomies={props.hasTaxonomies}
          syncMatterFiles={props.syncMatterFiles}
          isFlagged={isFlagged}
          priority={priority}
          showMoreButtons
        />
      }
    );
  }

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter File?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.fileId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  }

  const deleteCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterFile(matterId, activityId).then(() => {
      // Reload Summary
      pageActions.triggerReloadPage(false);
      // Sync matter files. Reload the whole grid, because we might have RecordableEvents that changed by deleting the email
      props.syncMatterFiles && props.syncMatterFiles();

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  }

  const deletePermissionModal = (email: string, isEditPermission: boolean) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this permission?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deletePermissionCallback(email, isEditPermission),
      show: true
    }
    modalActions.setModal(modal);
  };

  const deletePermissionCallback = (email: string, isEditPermission: boolean) => {
    setIsLoading(true);
    const data: DeleteMatterFilePermissionModel = {email: email};
    deleteMatterFilePermission(props.matterId, props.fileId, data).then(() => {
      if(isEditPermission) {
        const perm = matterFilePermissions?.editPermissions?.find(x => x.email == email);
        if(!perm?.isInherited) {
          setMatterFilePermissions({
            editPermissions: matterFilePermissions?.editPermissions?.filter(x => x.email != email) ?? [],
            viewPermissions: matterFilePermissions?.viewPermissions ?? []
          });
        }
      } 
      else {
        const perm = matterFilePermissions?.viewPermissions?.find(x => x.email == email);
        if(!perm?.isInherited) {
          setMatterFilePermissions({
            editPermissions: matterFilePermissions?.editPermissions ?? [],
            viewPermissions: matterFilePermissions?.viewPermissions?.filter(x => x.email != email) ?? []
          });
        }
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterFile?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"File Name"}
            value={matterFile?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"File Path"}
            value={matterFile?.pathToFile}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <BooleanField
            label={"Is Deleted"}
            value={matterFile?.isDeletedOnStorage ?? false}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Created On"}
            value={matterFile?.storageCreatedDate ?
              moment(matterFile?.storageCreatedDate).format(DateFormat.MomentWithTime) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Created By"}
            value={matterFile?.storageCreatedBy}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Last Modified On"}
            value={matterFile?.storageLastModifiedDate ?
              moment(matterFile?.storageLastModifiedDate).format(DateFormat.MomentWithTime) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Last Modified By"}
            value={matterFile?.storageLastModifiedBy}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Size"}
            value={matterFile?.size ? formatFileSize(matterFile?.size, 2) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Additional Note"}
            value={matterFile?.additionalNote}
          />
        </Col>
      </Row>

      { isFlagged !== undefined || priority !== undefined ?
        <Row>
          { isFlagged !== undefined &&
            <Col sm={6}>
              <BooleanField
                label={"Is Flagged"}
                value={isFlagged ?? false}
              />
            </Col>
          }
          { priority !== undefined &&
            <Col sm={6}>
              <Field
                label={"Priority"}
                value={priority !== undefined ? getPriority(priority) : "—"}
              />
            </Col>
          }
        </Row>
      : null }

      {matterFile?.isDeletedOnStorage == false && 
        <>
          <Row>
            <Col>
              <div className="departments">
                <ListSlidingPanel
                  listItems={matterFilePermissions?.editPermissions?.map(x => ({id: x.email, name: x.email, hideDeleteBtn: x.isInherited})) ?? []}
                  listButtons={!matterFile.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) => deletePermissionModal(id, true)
                    }
                  ] : []}
                >
                  People that can view & edit
                </ListSlidingPanel>
              </div>
              <div className="departments">
                <ListSlidingPanel
                  listItems={matterFilePermissions?.viewPermissions?.map(x => ({id: x.email, name: x.email, hideDeleteBtn: x.isInherited})) ?? []}
                  listButtons={!matterFile.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) => deletePermissionModal(id, false)
                    }
                  ] : []}
                >
                  People that can view
                </ListSlidingPanel>
              </div>
            </Col>
          </Row>
        </>
      }

      <Row className={!matterFile?.isDeletedOnStorage ? 'mt-0' : ''}>
        <Col>
          <div className="departments">
            <div className="lp-simple-list">
              <div className="lp-color-title secondary mb-4">
                History
              </div>
            </div>
            <CustomTable
              columnDefs={columnDefsFileHistoryCustomTable()}
              rowData={matterFile?.matterFileHistory ?? []}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ViewMatterFile;
