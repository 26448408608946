import { cloneElement, ReactElement, useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { decreasePriorityMatterActivityHistory, deleteMatterIncidentalExpense, flagMatterActivityHistory, getMatterIncidentalExpenseById, increasePriorityMatterActivityHistory, unflagMatterActivityHistory } from "actions/matter";
import moment from "moment";
import { DateFormat } from "utils/constants";
import ListSlidingPanel from "components/List/ListSlidingPanel";
import _ from 'lodash';
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import BooleanField from "components/Fields/BooleanField";
import { formatCurrency, getDecreasedPriority, getIncreasedPriority } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { useAppSelector } from "hooks/appSelector";
import EditMatterIncidentalExpenseForm from "../EditMatterIncidentalExpense/EditMatterIncidentalExpenseForm";
import MatterIncidentalExpenseWriteOffForm from "../MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import LinkMatterIncidentalExpenseForm from "../LinkMatterIncidentalExpense/LinkMatterIncidentalExpenseForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import usePageActions from "actions/page";
import { Priority } from "enums/Priority";
import { getPriority } from "utils/enums";

type Props = {
  matterId: string,
  matterIncidentalExpenseId: string,
  activityHistoryId?: string,
  hasTaxonomies?: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
  hideEdit?: boolean,
  isFlagged?: boolean,
  priority?: Priority,
}

function ViewMatterIncidentalExpense(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterIncidentalExpense, setMatterIncidentalExpense] = useState<MatterIncidentalExpenseModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    setIsLoading(true);
    getMatterIncidentalExpenseById(props.matterId, props.matterIncidentalExpenseId).then((response) => {
      setMatterIncidentalExpense(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [props.matterIncidentalExpenseId]);

  useEffect(() => {
    if (matterIncidentalExpense) {
      setSlidingPanelEditButton();
      setSlidingPanelMoreButtons();
    }
  }, [matterIncidentalExpense, isFlagged, priority]);

  const setSlidingPanelEditButton = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) &&
      !props.hideEdit && !matterIncidentalExpense?.matter?.isClosed && !matterIncidentalExpense?.isBilled && !matterIncidentalExpense?.writeOffAmount) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();
  
        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Incidental Expense",
          children: <EditMatterIncidentalExpenseForm
            matterId={props.matterId}
            matterIncidentalExpenseId={props.matterIncidentalExpenseId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  };

  const setSlidingPanelMoreButtons = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) && 
      !matterIncidentalExpense?.matter?.isClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        // Taxonomies
        ...((props.hasTaxonomies && props.activityHistoryId) ? [
          {
            type: ActionButtonTypes.EditTaxonomyTerms,
            callback: () => onClickTaxonomies(),
          }
        ] : []),
        // Link to Recordable Event
        ...(!matterIncidentalExpense?.matterRecordableEventId && props.activityHistoryId ? [
          {
            type: ActionButtonTypes.LinkToRecordableEvent,
            callback: () => onClickLinkIncidentalExpenses(),
          },
        ] : []),
        // Write Off
        ...(!matterIncidentalExpense?.isBilled ? [
          {
            type: ActionButtonTypes.WriteOff,
            callback: () => onClickWriteOff(),
          },
        ] : []),
        // Flag
        ...(props.activityHistoryId && isFlagged !== undefined ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...(props.activityHistoryId && priority !== undefined ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        ...(!matterIncidentalExpense?.isBilled ? [
          {
            type: ActionButtonTypes.Delete,
            callback: () => onClickDelete(),
          }
        ] : []),
      ]);
    }
  };

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  }

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  }

  const onClickLinkIncidentalExpenses = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Link Incidental Expense",
        children: <LinkMatterIncidentalExpenseForm
          matterId={props.matterId}
          matterIncidentalExpenseId={props.matterIncidentalExpenseId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  }

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Incidental Expense?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterIncidentalExpenseId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterIncidentalExpenseId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterIncidentalExpense(matterId, matterIncidentalExpenseId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      if(props.syncMatterFiles) {
        // Reload summary
        pageActions.triggerReloadPage(false);
        // Call sync matter files
        props.syncMatterFiles && props.syncMatterFiles();
      }
      else {
        // Reload entire page
        pageActions.triggerReloadPage();
      }
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  function onClickWriteOff() {
    //close View Matter Incidental Expense sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Incidental Expense WriteOff sliding panel having onCancel event to reopen 
    //View Matter Incidental Expense sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Incidental Expense Write-Off",
      children: <MatterIncidentalExpenseWriteOffForm
          matterId={props.matterId}
          matterIncidentalExpenseId={props.matterIncidentalExpenseId}
          onSubmitCallback={onChildPanelClose}
          onDeleteCallback={onChildPanelClose}
        />,
      onCancel: onChildPanelClose
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col sm={6}>
          <Field
            label={"Fee Earner"}
            value={matterIncidentalExpense?.user?.name}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Client"}
            value={matterIncidentalExpense?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Date"}
            value={matterIncidentalExpense?.date ? moment(matterIncidentalExpense?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Display Name"}
            value={matterIncidentalExpense?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterIncidentalExpense?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Field
            label={"Gross Value"}
            value={matterIncidentalExpense?.grossValue ? formatCurrency(matterIncidentalExpense?.grossValue) : "—"}
          />
        </Col>
        <Col sm={4} className="mt-4 mt-sm-0">
          <Field
            label={"VAT Value"}
            value={matterIncidentalExpense?.vatValue ? formatCurrency(matterIncidentalExpense?.vatValue) : "—"}
          />
        </Col>
        <Col sm={4} className="mt-4 mt-sm-0">
          <Field
            label={"Net Value"}
            value={matterIncidentalExpense?.netValue ? formatCurrency(matterIncidentalExpense?.netValue) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <BooleanField
            label={"Paid By User's Own Funds"}
            value={matterIncidentalExpense?.paidByUserOwnFunds ?? false}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <BooleanField
            label={"Is Billed"}
            value={matterIncidentalExpense?.isBilled ?? false}
          />
        </Col>
      </Row>
      {matterIncidentalExpense?.writeOffAmount &&
        <>
          <Row>
            <Col sm={6}>
              <Field
                label={"Write-Off Date"}
                value={matterIncidentalExpense?.writeOffDate ? moment(matterIncidentalExpense?.writeOffDate).format(DateFormat.Moment) : "—"}
              />
            </Col>
            <Col sm={6} className="mt-4 mt-sm-0">
              <Field
                label={"Write-Off Amount"}
                value={matterIncidentalExpense?.writeOffAmount ? formatCurrency(matterIncidentalExpense?.writeOffAmount) : "—"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Write-Off Description"}
                value={matterIncidentalExpense?.writeOffDescription}
              />
            </Col>
          </Row>
        </>
      }

      { isFlagged !== undefined || priority !== undefined ?
        <Row>
          { isFlagged !== undefined &&
            <Col sm={6}>
              <BooleanField
                label={"Is Flagged"}
                value={isFlagged ?? false}
              />
            </Col>
          }
          { priority !== undefined &&
            <Col sm={6}>
              <Field
                label={"Priority"}
                value={priority !== undefined ? getPriority(priority) : "—"}
              />
            </Col>
          }
        </Row>
      : null }

      <Row>
        <Col>
          <div className="departments">
            <ListSlidingPanel
              listItems={_.orderBy(matterIncidentalExpense?.files?.map(x => ({id: x.id, name: x.name, webUrl: x.webUrl})) ?? [], x => x.name.toLowerCase())}
              listButtons={loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) ? [
                {
                  type: ActionButtonTypes.OpenFile
                }
              ] : []}
            >
              Proofs of Purchase
            </ListSlidingPanel>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ViewMatterIncidentalExpense;
