import { useCallback, useEffect, useState } from 'react';
import CustomCalendar from 'components/Calendar/CustomCalendar';
import { Button, Card } from 'react-bootstrap';
import Title from 'components/Title';
import moment from 'moment';
import { MdArrowOutward, MdOutlineAdd, MdRefresh } from 'react-icons/md';
import { CalendarEventModel } from 'models/CalendarEventModel';
import useSlidingPanelActions from 'actions/slidingPanel';
import { getCalendarView } from 'actions/lte';
import { View, Views } from 'react-big-calendar';
import ViewCalendarEvent from 'containers/Calendar/ViewCalendarEvent/ViewCalendarEvent';
import { Link } from "react-router-dom";
import ManualAddAppointments from 'containers/Matter/AddAppointments/ManualAddAppointments';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { UserCalendarSettingsModel } from 'models/view/UserCalendarSettingsModel';
import { getUserCalendarSettings } from 'actions/user';
import { DayOfWeek } from 'enums/DayOfWeek';

export default function DashboardCalendar() {
  const [events, setEvents] = useState<CalendarEventModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const [startDate, setStartDate] = useState<Date>(moment().startOf('week').toDate());
  const [endDate, setEndDate] = useState<Date>(moment().endOf('week').toDate());
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [view, setView] = useState<View>(Views.WEEK);
  const loggedInUser = useAppSelector((state) => state.user);
  const [windowOnFocus, setWindowOnFocus] = useState(true);
  const user = useAppSelector((state) => state.user);
  const [userCalendarSettings, setUserCalendarSettings] = useState<UserCalendarSettingsModel>();

  const checkTokensOnFocus = () => {
    setWindowOnFocus(true);
  };

  const onBlur = () => {
    setWindowOnFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', checkTokensOnFocus);
    return () => {
      window.removeEventListener('focus', checkTokensOnFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const getEventsForInterval = useCallback(() => {
    if(startDate != null && endDate != null) {
      setIsLoading(true);
      const userTimeZone = moment.tz.guess();
      getCalendarView(startDate.toISOString(), endDate.toISOString(), userTimeZone).then((response) => {
        setEvents(response.data);
        //clear previous errors
        setGenericErrors(null);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setIsLoading(true);
    getUserCalendarSettings(user.userId!).then((calendarSettings) => {
      setUserCalendarSettings(calendarSettings.data);

      // Get events after getting user calendar settings to prevent events from jumping around
      getEventsForInterval();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }, [getEventsForInterval]);

  const onRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    setIsLoading(true);
    setEvents([]);
  
    if (Array.isArray(range)) {
      setStartDate(range[0]);
      if (range.length === 1) {
        const newEndDate = moment(range[0]).add(1, 'day');
        setEndDate(newEndDate.toDate());
      } else {
        setEndDate(moment(range[range.length - 1]).add(1, 'day').toDate());
      }
    } else {
      setStartDate(range.start);
      setEndDate(moment(range.end).add(1, 'day').toDate());
    }
  };

  const changeSelectedEvent = useCallback((event: CalendarEventModel) => {
    const eventIndex = events.findIndex((x) => x.graphId === event.graphId);
    if(eventIndex !== -1) {
      const newEvents = [...events];
      newEvents[eventIndex] = event;
      setEvents(newEvents);
    }
  }, [events]);
  
  const handleEventClick = (event: any) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'View Calendar Event',
      children: <ViewCalendarEvent
        eventData={event}
        reloadCalendarEvents={getEventsForInterval}
        changeCalendarEvent={changeSelectedEvent}
      />
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(windowOnFocus) {
        getEventsForInterval();
      }
    }, 300000);

    return () => clearInterval(interval);
  }, [getEventsForInterval, windowOnFocus]);

  const addAppointmentsToMatter = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Import Appointments To Matter",
        children: <ManualAddAppointments />
      }
    );
  }

  return (
    <>
      <Title type="section" title="Calendar">
        {
          <>
            {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageCalendars) && 
              <div onClick={addAppointmentsToMatter} className="link">
                Import appointments to matter
                <MdOutlineAdd />
              </div>
            }
            <Link to="/calendar" rel="noopener noreferrer" className="link">
              View full calendar
              <MdArrowOutward />
            </Link>
            <Button onClick={getEventsForInterval} className="btn-icon" variant="primary">
              <MdRefresh />
            </Button>
          </>
        }
      </Title>

      {genericErrors && <div className="lp-errors calendar-errors">{genericErrors}</div>}

      <Card className="with-calendar dashboard">
        <Card.Body>
          <CustomCalendar
            handleEventClick={handleEventClick}
            onRangeChange={onRangeChange}
            events={events}
            isLoading={loading}
            view={view}
            setView={setView}
            selectable={loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageCalendars)}
            firstDayOfWeek={userCalendarSettings?.firstDayOfWeek ? DayOfWeek[userCalendarSettings.firstDayOfWeek as keyof typeof DayOfWeek] ?? 0 : 0}
          />
        </Card.Body>
      </Card>
    </>
  );
}
