import { useEffect, useState } from "react";
import TreeSelectForFilter from "components/TreeSelect";
import { CheckedStrategy } from "rc-tree-select/lib/utils/strategyUtil";
import TreeSelect from "rc-tree-select";
import { CustomFloatingFilterProps } from "ag-grid-react";

interface Props extends CustomFloatingFilterProps {
  endpointCall: Function,
  showCheckedStrategy?: CheckedStrategy,
  defaultModel?: any
}

export const DropdownFloatingFilter = ({ endpointCall, showCheckedStrategy, model, onModelChange, defaultModel }: Props) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if(defaultModel) {
      onModelChange(defaultModel);
    }
    else {
      onModelChange(null);
    }

    endpointCall().then((response: any) => {
      setData(response.data);
    })
    .catch((error: any) => {
    });
  }, []);

  const onOptionChange = (input: any) => {
    // filter out null values from input
    input = input?.filter((x: any) => x != null);

    if (input?.length === 0) {
      // clear the filter
      onModelChange(null);
      return;
    }

    onModelChange(input);
  }

  return (
    <TreeSelectForFilter
      data={data}
      value={model ? model.filter((x: any) => x != null) : undefined}
      onChange={onOptionChange}
      showCheckedStrategy={showCheckedStrategy ?? TreeSelect.SHOW_CHILD}
      treeCheckable={true}
    />
  );
}
