import { MatterTypeIds } from "enums/MatterTypeIds";
import { CreateOrUpdateMatterModel } from "models/create/CreateOrUpdateMatterModel";
import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";

export const validationSuite = create((data: CreateOrUpdateMatterModel) => {
  enforce.extend({isDate});

  test('displayName', 'Display name is required', () => {
    enforce(data.displayName).isNotEmpty();
    enforce(data.displayName).isString();
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });

  test('conflictStatusId', 'Please select Conflict', () => {
    enforce(data.conflictStatusId).isNotEmpty();
    enforce(data.conflictStatusId).isString();
  });

  test('fundingAssuranceStatusId', 'Please select Funding Assurance', () => {
    enforce(data.fundingAssuranceStatusId).isNotEmpty();
    enforce(data.fundingAssuranceStatusId).isString();
  });

  test('riskRatingId', 'Please select Risk Rating', () => {
    enforce(data.riskRatingId).isNotEmpty();
    enforce(data.riskRatingId).isString();
  });

  test('chargingSchemeId', 'Please select Charging Scheme', () => {
    enforce(data.chargingSchemeId).isNotEmpty();
    enforce(data.chargingSchemeId).isString();
  });

  test('matterTypeId', 'Please select Type', () => {
    enforce(data.matterTypeId).isNotEmpty();
    enforce(data.matterTypeId).isString();
  });

  test('priority', 'Please select Priority', () => {
    enforce(data.priority).isNumber();
  });
});