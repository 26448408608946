import { CustomFloatingFilterProps } from "ag-grid-react";
import CustomSelect from "components/Select/Select";

interface Props extends CustomFloatingFilterProps {
  values: {id: string, name: string}[]
}

export const BooleanFloatingFilter = ({ values, model, onModelChange }: Props) => {
  const onOptionChange = (input: any) => {
    if (input?.length === 0) {
      // clear the filter
      onModelChange(null);
      return;
    }

    onModelChange(input);
  }

  return (
    <CustomSelect
      id="options"
      className="lp-select"
      options={values}
      value={model?.map((x: any) => x.id) ?? []}
      onChange={onOptionChange}
      styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      isClearable
      isMulti
    />
  );
}
