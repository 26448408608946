import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { IDoesFilterPassParams } from "ag-grid-enterprise";
import { useCallback } from "react";
import _ from 'lodash';

interface Props extends CustomFilterProps {
  property: string;
}

export const DropdownFilter = ({ property, model }: Props ) => {
  const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
    if (!model || !Array.isArray(model)) {
      return false;
    }

    // filtering logic
    return model.some((x: any) => x == _.get(params.node.data, property));
  }, [model]);

  // register filter callbacks with the grid
  useGridFilter({ doesFilterPass });

  return (
    <div style={{ display: "inline-block", width: "200px", height: "200px" }}>
      Use a floating filter!
    </div>
  );
}
