import { cloneElement, ReactElement, useEffect, useState } from "react";
import { MatterRecordableEventModel } from "models/view/MatterRecordableEventModel";
import { decreasePriorityMatterActivityHistory, deleteMatterRecordableEvent, flagMatterActivityHistory, getActivitiesForChargingSchemeFeeEarner, getMatterRecordableEventWithItems, increasePriorityMatterActivityHistory, unflagMatterActivityHistory } from "actions/matter";
import { Col, Row } from "react-bootstrap";
import Field from "components/Fields/Field";
import Loader from "components/Loader/index";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { ActivityDropdownForRecordTimeModel } from "models/view/ActivityDropdownForRecordTimeModel";
import ViewMatterRecordableEventActivityList from "../ViewMatterRecordableEventList/ViewMatterRecordableEventActivityList";
import ViewMatterRecordableEventIncidentalExpenseList from "../ViewMatterRecordableEventList/ViewMatterRecordableEventIncidentalExpenseList";
import useSlidingPanelActions from "actions/slidingPanel";
import EditMatterRecordableEvent from "../EditMatterRecordableEvent/EditMatterRecordableEvent";
import { useAppSelector } from "hooks/appSelector";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import UnlinkMatterIncidentalExpenseForm from "../UnlinkMatterIncidentalExpense/UnlinkMatterIncidentalExpenseForm";
import usePageActions from "actions/page";
import { Priority } from "enums/Priority";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { getDecreasedPriority, getIncreasedPriority } from "utils/misc";
import BooleanField from "components/Fields/BooleanField";
import { getPriority } from "utils/enums";

type Props = {
  matterId: string,
  recordableEventId: string,
  isBilled?: boolean,
  activityHistoryId?: string,
  isMatterClosed?: boolean,
  hasTaxonomies?: boolean,
  showMoreButtons?: boolean,
  isFlagged?: boolean,
  priority?: Priority,
}

export default function ViewMatterRecordableEvent(props: Props) {
  const [matterRecordableEvent, setMatterRecordableEvent] = useState<MatterRecordableEventModel | undefined>(undefined);
  const [activities, setActivities] = useState<ActivityDropdownForRecordTimeModel[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    setIsLoading(true);
    getMatterRecordableEventWithItems(props.matterId, props.recordableEventId).then((response) => {
      getActivitiesForChargingSchemeFeeEarner(response.data.chargingSchemeId, response.data.feeEarnerLevelId).then((activitiesResponse) => {
        setMatterRecordableEvent(response.data);
        setActivities(activitiesResponse.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setIsLoading(false);
    });
  }, [props.matterId, props.recordableEventId]);

  useEffect(() => {
    setSlidingPanelEditButton();
    setSlidingPanelMoreButtons();
  }, [isFlagged, priority]);

  const setSlidingPanelEditButton = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
      !props.isMatterClosed && !props.isBilled && props.matterId) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();
  
        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Recordable Event",
          children: <EditMatterRecordableEvent
            matterId={props.matterId!}
            recordableEventId={props.recordableEventId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setSlidingPanelMoreButtons = () => {
    if (!props.isMatterClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        // Taxonomies
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
          props.hasTaxonomies && props.activityHistoryId ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        // Unlink Incidental Expenses
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) ? [{
          type: ActionButtonTypes.UnlinkIncidentalExpenses,
          callback: () => onClickUnlinkIncidentalExpenses(),
        }] : []),
        // Flag
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
          props.activityHistoryId && isFlagged !== undefined) ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
          props.activityHistoryId && priority !== undefined) ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterRecordableEvents) &&
          !props.isBilled ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  }

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickUnlinkIncidentalExpenses = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Unlink Incidental Expense",
        children: <UnlinkMatterIncidentalExpenseForm
          matterId={props.matterId}
          recordableEventId={props.recordableEventId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Recordable Event?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.recordableEventId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteMatterRecordableEvent(matterId, activityId).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterRecordableEventId !== activityId));
      pageActions.triggerReloadPage(false);

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col sm={6}>
          <Field
            label={"Fee Earner"}
            value={matterRecordableEvent?.user?.name}
          />
        </Col>

        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Fee Earner Level"}
            value={matterRecordableEvent?.feeEarnerLevel?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterRecordableEvent?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Date"}
            value={matterRecordableEvent?.date ? moment(matterRecordableEvent?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Display Name"}
            value={matterRecordableEvent?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterRecordableEvent?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Charging Scheme"}
            value={matterRecordableEvent?.chargingScheme?.name}
          />
        </Col>
        {/*<Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Event Type"}
            value={matterRecordableEvent?.recordableEventType?.name}
          />
        </Col>*/}
      </Row>

      { isFlagged !== undefined || priority !== undefined ?
        <Row>
          { isFlagged !== undefined &&
            <Col sm={6}>
              <BooleanField
                label={"Is Flagged"}
                value={isFlagged ?? false}
              />
            </Col>
          }
          { priority !== undefined &&
            <Col sm={6}>
              <Field
                label={"Priority"}
                value={priority !== undefined ? getPriority(priority) : "—"}
              />
            </Col>
          }
        </Row>
      : null }

      <Row>
        <Col>
          <ViewMatterRecordableEventActivityList
            matterRecordableItems={matterRecordableEvent?.matterRecordableItems ?? []}
            activities={activities}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ViewMatterRecordableEventIncidentalExpenseList
            matterIncidentalExpenses={matterRecordableEvent?.matterIncidentalExpenses ?? []}
          />
        </Col>
      </Row>
    </>
  );
}
