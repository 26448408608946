import Loader from "components/Loader/index";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { Card } from "react-bootstrap";
import './style.scss';
import React from "react";
import { MatterModel } from "models/view/MatterModel";

type Props = {
  listItems: MatterModel[],
  listButtons: Array<{type: ActionButtonTypes, callback: Function, id?: string}>,
  onClick?: Function,
  selected?: string,
  loadState?: boolean
}

export default function ListMatters(props: Props) {
  return (
    <Card className={`with-list${props.listItems.length == 0 ? ' empty-list' : ' dashboard-list'}`}>
      <div className="inner-content">
        <Card.Header>
          <div>{"Matter"}</div>
          <div>{"Case Manager"}</div>
          <div>{"Stage"}</div>
          {props.listButtons.length != 0 && <div></div>}
        </Card.Header>
        {props.loadState && <Loader inlineLoader={props.loadState} />}
        <Card.Body className="lp-list-grid">
          {props.listItems.length == 0 &&
            <div className="lp-empty list">
              <div className="image"></div>
              <div className="text">No matters found!</div>
            </div>
          }

          {props.listItems.map((x: MatterModel, index: number) => {
            return (
              <React.Fragment key={index}>
                <div className="lp-list-row">
                  <div>
                    {x.fileNumber + ' (' + x.displayName + ')' }
                  </div>
                  <div>
                    {x.caseManager?.name}
                  </div>
                  <div>
                    {x.matterStage?.name}
                  </div>
                  {props.listButtons.length != 0 &&
                    <div className="lp-list-action">
                      {
                        props.listButtons.map((el: {type: ActionButtonTypes, callback: Function, id?: string}, index: number) => 
                          IconButtonGrid({...el, callback: () => {
                            el.callback(x.id)
                          }, id: x.id + index.toString()})
                        )
                      }
                    </div>
                  }
                </div>
              </React.Fragment>
            )
          })}
        </Card.Body>
      </div>
    </Card>
  );
}
