import Loader from 'components/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import useSlidingPanelActions from 'actions/slidingPanel';
import DatePicker from "react-datepicker";
import { DateFormat } from 'utils/constants';
import moment from 'moment';
import { checkEventAttendees, getDiaryManagerEvent, getLawPageRooms, getLteCalendarSettingsById, getMeetingTimes, updateDiaryManagerEvent } from 'actions/lte';
import { CreateOrUpdateCalendarEventModel } from 'models/create/CreateOrUpdateCalendarEventModel';
import CustomSelect from 'components/Select/Select';
import { getCaseManager, getDiaryManager, getEventAttendeeSuggestions, getOpenMattersSummaryForLTE } from 'actions/matter';
import CustomCreatableSelect from 'components/Select/CreatableSelect';
import { getCalendarFreeBusyStatusDropdownValues, getCalendarReminderBeforeStatusDropdownValues } from 'utils/misc';
import { vestResolver } from '@hookform/resolvers/vest';
import { CreatableDropDownOptionModel } from 'models/view/CreatableDropDownOptionModel';
import ColoredOption from 'components/Select/ColoredOption';
import { useAppSelector } from 'hooks/appSelector';
import { FreeBusyAttendeeCheckModel } from 'models/create/FreeBusyAttendeeCheckModel';
import { FreeBusyAvailabilityViewModel } from 'models/view/FreeBusyAttendeeAvailabilityViewModel';
import { MeetingTimeSuggestedTimeModel } from 'models/view/MeetingTimeSuggestedTimeModel';
import { LteCalendarSettingsModel } from 'models/view/LteCalendarSettingsModel';
import Field from "components/Fields/Field";
import FormErrorButton from 'components/Buttons/FormErrorButton';
import { getValidationSuite } from './validationSuite';

type Props = {
  lteId: string,
  graphId: string;
  hideMatterSelect?: boolean; // Hide matter select if edit is opened from matter appointments
  onSubmitCallback: Function;
  reloadEvents?: Function;
  changeEvent?: Function;
};

export default function EditCalendarEvent(props: Props) {
  const slidingPanelActions = useSlidingPanelActions();
  const [loading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState<string | null>(null);

  const [matterFileNumber, setMatterFileNumber] = useState<string | undefined>();
  const [diaryManager, setDiaryManager] = useState<string>();
  const [eventAttendeeSuggestions, setEventAttendeeSuggestions] = useState<CreatableDropDownOptionModel[]>();
  const [optionalEventAttendeeSuggestions, setOptionalEventAttendeeSuggestions] = useState<CreatableDropDownOptionModel[]>();
  const [lteCalendarSettings, setLteCalendarSettings] = useState<LteCalendarSettingsModel | undefined>(undefined);
  const [availabilityStatus, setAvailabilityStatus] = useState<boolean | undefined>(undefined);
  const [suggestedTimes, setSuggestedTimes] = useState<MeetingTimeSuggestedTimeModel[]>([]);
  const [calendarEvent, setCalendarEvent] = useState<CreateOrUpdateCalendarEventModel>();
  const user = useAppSelector((state) => state.user);
  const currentSlidingPanelState = useAppSelector((s) => s.slidingPanel);

  useEffect(() => {
    setIsLoading(true);
    const promises: Promise<any>[] = [];
    promises.push(getDiaryManagerEvent(props.lteId, props.graphId));
    promises.push(getDiaryManager(props.lteId!));
    promises.push(getLteCalendarSettingsById(props.lteId!));
    Promise.all(promises).then(
      ([eventResponse, diaryManagerResponse, lteCalendarSettingsResponse]) => {
        const event = eventResponse.data
        setCalendarEvent(event);
        setDiaryManager(diaryManagerResponse.data);
        setLteCalendarSettings(lteCalendarSettingsResponse.data);
        let matterTag = "";
        if(event.matterReference) {
          const matterFileNumber = event.matterReference.split(" ")[0].trim();
          setMatterFileNumber(matterFileNumber);
          matterTag = `[LPID#${matterFileNumber}]`;
        }

        let initialState: CreateOrUpdateCalendarEventModel = {
          ...event,
          reminderMinutesBeforeStart: event.reminderMinutesBeforeStart?.toString(),
          showAs: event.showAs.toString(),
          title: event.matterReference ? event.title.replace(matterTag + " ", "") : event.title, // Remove matter file number from title
        };

        if(event.matterId != undefined && event.matterId.trim() != "") {
          onChangeMatter(event.matterId, diaryManagerResponse.data);
        }

        reset(initialState);
      }
    ).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, watch, setValue, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateCalendarEventModel>({
    resolver: vestResolver(getValidationSuite()),
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateCalendarEventModel) {
    setIsLoading(true);
    const requestBody = { ...data };

    if(requestBody.reminderMinutesBeforeStart == "-1") {
      requestBody.reminderMinutesBeforeStart = undefined;
    }

    updateDiaryManagerEvent(props.lteId, props.graphId, requestBody).then((response) => {
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.reloadEvents && props.reloadEvents();
      props.onSubmitCallback && props.onSubmitCallback();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const updateCalendar = (data: CreateOrUpdateCalendarEventModel) => {
    if(data.endDate > data.startDate) {
      if(props.changeEvent != undefined) {
        props.changeEvent(data);
      }
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  const onChangeMatter = useCallback((matterId?: string, diaryManagerParam?: string) => {
    const diaryManagerToAdd: string[] = diaryManager ? [diaryManager] : (diaryManagerParam ? [diaryManagerParam] : []);
    let requiredAttendees: string[] = []

    if(matterId) {
      getEventAttendeeSuggestions(props.lteId, matterId!).then((response) => {
        setEventAttendeeSuggestions(response.data);
        setOptionalEventAttendeeSuggestions(response.data);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
      getCaseManager(matterId).then((response) => {
        if(response.data.user.email == user.email || !user.email) {
          requiredAttendees = [response.data.user.email];
        } else {
          requiredAttendees = [response.data.user.email, user.email!];
        }
        if(diaryManagerToAdd.length > 0 && requiredAttendees.every(x => x != diaryManagerToAdd[0])) {
          requiredAttendees.unshift(...diaryManagerToAdd);
        }
        setValue('requiredAttendees', requiredAttendees);
      });
    } else {
      setEventAttendeeSuggestions([]);
      setOptionalEventAttendeeSuggestions([]);
      setValue('requiredAttendees', requiredAttendees);
    }
    setValue('optionalAttendees', []);
  }, [diaryManager])

  const onChangeIsAllDay = (value: boolean) => {
    if(value) {
      setValue('startDate', moment(watch('startDate')).startOf('day').toDate());
      setValue('endDate', moment(watch('startDate')).startOf('day').toDate());
    } else {
      setValue('startDate', calendarEvent!.startDate);
      setValue('endDate', calendarEvent!.endDate);
    }
    updateCalendar(watch());
  }

  const onChangeRequiredAttendees = (selectedOptions: CreatableDropDownOptionModel[]) => {
    setOptionalEventAttendeeSuggestions(eventAttendeeSuggestions?.filter(
      (x) => !selectedOptions.some((y) => y.label == x.label)
    ));
  }

  const checkAttendees = (
    requiredAttendees: string[], 
    optionalAttendees: string[],
    rooms: string[],
    isAllDay: boolean,
    startDate?: Date,
    endDate?: Date
  ) => {
    setAvailabilityStatus(undefined);
    setSuggestedTimes([]);
    let attendees = requiredAttendees ? requiredAttendees.concat(optionalAttendees) : optionalAttendees;
    if(attendees?.length == 0 || !startDate || !endDate) {
      return;
    }

    if(startDate > endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }
    const momentDate1 = moment(startDate);
    const momentDate2 = moment(endDate);
    const daysDifference = momentDate2.diff(momentDate1, 'days');

    if(daysDifference > 62) {
      setGenericErrors("The requested time duration specified is too long. The limit is 62 days.");
      return;
    }

    if(rooms.length > 0) {
      attendees = attendees.concat(rooms);
    }

    const body: FreeBusyAttendeeCheckModel = {
      lteId: props.lteId,
      attendees: attendees,
      startDate: startDate,
      endDate: isAllDay ? moment(endDate).add(1, 'days').toDate() : endDate,
    }
    checkEventAttendees(body).then((response) => {
      if(response.data.some((x: FreeBusyAvailabilityViewModel) => x.availabilityView.split('').some(y => y !== '0'))) {
        setAvailabilityStatus(false);
        getSuggestions(requiredAttendees, optionalAttendees, rooms, watch('matterId'), body.startDate, body.endDate);
      } 
      else
      {
        setAvailabilityStatus(true);
      }
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setGenericErrors(null);
    });
  }

  const getSuggestions = (
    requiredAttendees: string[], 
    optionalAttendees: string[],
    rooms: string[],
    matterId?: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    let attendees = requiredAttendees.concat(optionalAttendees);
    if(attendees.length == 0 || !startDate || !endDate) {
      return;
    }

    const momentDate1 = moment(startDate);
    const momentDate2 = moment(endDate);
    const minutes = momentDate2.diff(momentDate1, 'minutes');

    if(minutes > 720) {
      return;
    }

    if(startDate > endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }

    if(rooms.length > 0) {
      attendees = attendees.concat(rooms);
    }

    const body: FreeBusyAttendeeCheckModel = {
      matterId: matterId,
      lteId: props.lteId,
      attendees: attendees,
      startDate: startDate,
      endDate: endDate
    }
    getMeetingTimes(body).then((response) => {
      setSuggestedTimes(response.data);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    }).finally(() => {
      setGenericErrors(null);
    });
  }
  
  //useEffect(() => {
  //  checkAttendees(watch('requiredAttendees'), watch('optionalAttendees'), watch('rooms'), watch('isAllDay'), watch('startDate'), watch('endDate'));
  //}, [watch('requiredAttendees'), watch('rooms'), watch('optionalAttendees'), watch('startDate'), watch('endDate')]);

  const onChangeStartDate = (val?: Date) => {
    if(watch('isAllDay')) {
      setValue('endDate', moment(val).startOf('day').toDate());
    }
    else {
      setValue('endDate', moment(val).add(30, "minutes").toDate());
    }    
  }

  const onClickSuggestedTime = (startDate: Date, endDate: Date) => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);

    updateCalendar(watch());
  }

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      {availabilityStatus != undefined && (
        <div className={`lp-calendar-availability${availabilityStatus ? " free" : " busy"}`}>
          {availabilityStatus ? "All the Attendees are free in the specified time window." : "Some of the Attendees are not free in the specified time window."}
        </div>
      )}

      {suggestedTimes?.length != 0 && (
        <>
          <div className="lp-calendar-suggestions-title">Available time slot suggestions:</div>
          <div className="lp-calendar-suggestions">
            {suggestedTimes?.map((x: MeetingTimeSuggestedTimeModel, index: number) => (
              <div key={index} onClick={() => onClickSuggestedTime(x.startDate, x.endDate)}>
                <Field
                  label={"Start Date"}
                  value={moment(x.startDate).format(DateFormat.MomentWithTime)}
                />
                <Field
                  label={"End Date"}
                  value={moment(x.endDate).format(DateFormat.MomentWithTime)}
                />
              </div>
            ))}
          </div>
        </>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        { !props.hideMatterSelect &&
          <Row>
            <Form.Group as={Col} controlId="matterId">
              <Form.Label>Matter</Form.Label>
              <Controller
                control={control}
                name="matterId"
                shouldUnregister={true}
                render={({field: { onChange, value, ref }}) => (
                  <CustomSelect
                    id="matterId"
                    inputRef={ref}
                    className={`lp-select${errors?.matterId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getOpenMattersSummaryForLTE(props.lteId)}
                    value={value}
                    onChange={val => { 
                      onChange(val?.id ?? null); 
                      onChangeMatter(val?.id);
                      setMatterFileNumber(val?.name.split(" ")[0].trim());
                    }}
                    isClearable
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.matterId?.message && (errors.matterId.message)}
              </Form.Text>
            </Form.Group>
          </Row>
        }

        <Row>
          <Form.Group as={Col} sm={6} controlId="title">
            <Form.Label className="required">Title</Form.Label>
            <InputGroup>
              {matterFileNumber &&
                <InputGroup.Text id="basic-addon1">{`[LPID#${matterFileNumber}]`}</InputGroup.Text>
              }
              <Form.Control
                type="text"
                className={`${errors?.title?.message ? 'invalid' : ''}`}
                {...register('title', {shouldUnregister: true})}
                onBlur={() => updateCalendar(watch())}
              />
            </InputGroup>
            <Form.Text className="lp-error">
              {errors?.title?.message && errors.title.message}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="showAs">
            <Form.Label className="required">Show As</Form.Label>
            <Controller
              control={control}
              name="showAs"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="showAs"
                  inputRef={ref}
                  className={`lp-select${errors?.matterId?.message ? ' invalid' : ''}`}
                  options={getCalendarFreeBusyStatusDropdownValues()}
                  formatOptionLabel={ColoredOption}
                  value={value}
                  onChange={val => (onChange(val?.id ?? null), updateCalendar(watch()))}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.showAs?.message && (errors.showAs.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          {!watch('matterId') &&
            <Form.Group as={Col} sm={6} controlId="private">
              <Form.Label>Private</Form.Label>
              <Controller
                control={control}
                name="private"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="private">
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="isAllDay">
            <Form.Label>Is All Day</Form.Label>
            <Controller
              control={control}
              name="isAllDay"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isAllDay">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                      onChangeIsAllDay(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="startDate">
            <Form.Label className="required">Start Date</Form.Label>
            <Controller
              control={control}
              name="startDate"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <DatePicker
                  className={`${errors?.startDate?.message ? 'invalid' : ''}`}
                  dateFormat={watch('isAllDay') ? DateFormat.Datepicker : DateFormat.DatepickerWithTime}
                  selected={value ?? null}
                  onChange={(val) => (onChange(val ?? null), onChangeStartDate(val != null ? val : undefined), updateCalendar(watch()))}
                  onBlur={() => updateCalendar(watch())}
                  showTimeSelect={!watch('isAllDay')}
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.startDate?.message && (errors.startDate.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="endDate">
            <Form.Label className="required">End Date</Form.Label>
            <Controller
              control={control}
              name="endDate"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <DatePicker
                  className={`${errors?.endDate?.message ? 'invalid' : ''}`}
                  dateFormat={watch('isAllDay') ? DateFormat.Datepicker : DateFormat.DatepickerWithTime}
                  selected={value ?? null}
                  onChange={(val) => (onChange(val ?? null), updateCalendar(watch()))}
                  showTimeSelect={!watch('isAllDay')}
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.endDate?.message && (errors.endDate.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="requiredAttendees">
            <Form.Label>Required Attendees</Form.Label>
            <Controller
              control={control}
              name="requiredAttendees"
              shouldUnregister={true}
              render={({field: { value, onChange }}) => (
                <CustomCreatableSelect
                  id="requiredAttendees"
                  className={`lp-select${errors?.requiredAttendees?.message ? ' invalid' : ''}`}
                  options={eventAttendeeSuggestions}
                  onChange={(selectedOptions) => {
                    onChange(selectedOptions ? selectedOptions.map((option: any) => option.value) : []);
                    onChangeRequiredAttendees(selectedOptions)
                  }}
                  value={value}
                  isClearable
                  isMulti
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.requiredAttendees?.message && errors.requiredAttendees.message}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="optionalAttendees">
            <Form.Label>Optional Attendees</Form.Label>
            <Controller
              control={control}
              name="optionalAttendees"
              shouldUnregister={true}
              render={({field: { value, onChange }}) => (
                <CustomCreatableSelect
                  id="optionalAttendees"
                  className={`lp-select${errors?.optionalAttendees?.message ? ' invalid' : ''}`}
                  options={optionalEventAttendeeSuggestions}
                  onChange={(selectedOptions) =>
                    {
                      onChange(selectedOptions ? selectedOptions.map((option: any) => option.value) : []);
                    }
                  }
                  value={value}
                  isClearable
                  isMulti
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.optionalAttendees?.message && errors.optionalAttendees.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="reminderMinutesBeforeStart">
            <Form.Label className="required">Reminder Before Start</Form.Label>
            <Controller
              control={control}
              name="reminderMinutesBeforeStart"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="reminderMinutesBeforeStart"
                  inputRef={ref}
                  className={`lp-select${errors?.reminderMinutesBeforeStart?.message ? ' invalid' : ''}`}
                  options={getCalendarReminderBeforeStatusDropdownValues()}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.reminderMinutesBeforeStart?.message && (errors.reminderMinutesBeforeStart.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="location">
            <Form.Label>Location</Form.Label>
            <Controller
              control={control}
              name="location"
              shouldUnregister={true}
              render={({field: { value, onChange, onBlur }}) => (
                <CustomCreatableSelect
                  id="location"
                  className={`lp-select${errors?.location?.message ? ' invalid' : ''}`}
                  value={value}
                  onChange={val => onChange(val?.value ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.location?.message && errors.location.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="isOnlineMeeting">
            <Form.Label>Is Online Meeting</Form.Label>
            <Controller
              control={control}
              name="isOnlineMeeting"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isOnlineMeeting">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />  
          </Form.Group>

          {lteCalendarSettings?.hasAccessToLawPageRooms && 
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="rooms">
              <Form.Label>LawPage Rooms</Form.Label>
              <Controller
                control={control}
                name="rooms"
                shouldUnregister={true}
                render={({field: { value, onChange }}) => (
                  <CustomSelect
                    id="rooms"
                    className={`lp-select${errors?.rooms?.message ? ' invalid' : ''}`}
                    endpointCall={() => getLawPageRooms(props.lteId)}
                    onChange={(selectedOptions) => {
                      onChange(selectedOptions ? selectedOptions.map((option: any) => option.id) : []);
                    }}
                    value={value}
                    isClearable
                    isMulti
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.rooms?.message && errors.rooms.message}
              </Form.Text>
            </Form.Group>
          }
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label>
              Description
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              {...register("description", {shouldUnregister: true})}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
